define('touch/services/navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // Attributes
    title: '',
    backRoute: null,
    // Computed
    hasBackButton: Ember.computed('backRoute', function () {
      return this.get('backRoute') ? true : false;
    })
  });
});