define('touch/services/smd-header-stack-service', ['exports', 'ember-sauce-material-design/services/smd-header-stack-service'], function (exports, _smdHeaderStackService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdHeaderStackService.default;
    }
  });
});