define('touch/components/scheduler-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    // Services
    scheduler: service('heating-scheduler'),
    // Attributes
    currentStep: computed.alias('scheduler.step'),
    line: null,
    textIndex: {
      1: ['Not every day is the same. You may need different schedules for different days.', 'Here, you can set the days which you’d like this schedule to be active. Select the days of the week above when you’d like to run the schedule.'],
      2: ['What time do you wake up?', 'The two main periods of heating are mornings and evenings. Here we will set the morning period for when you get out of bed until when you leave your home.'],
      3: ['What temperature would you like it to be in the mornings?'],
      4: ['What time do you leave your home?', 'The two main periods of heating are mornings and evenings. Here we will set the morning period for when you get out of bed until when you leave your home.'],
      5: ['What time do you get home?', 'Here we will set the evening period for when you get home until you go to bed.'],
      6: ['What temperature would you like it to be in the evening?'],
      7: ['What time do you go to bed?', 'Here we will set the evening period for when you get home until you go to bed.'],
      8: ['You can edit the name of your first schedule, below.', 'You can name each schedule to tell them apart. Each schedule will have a generic name by default, but it is recommended that you use a descriptive name so that you know what this schedule is for. For example, “work days” or “weekends”.'],
      9: ['You have started your first schedule!', 'If you feel that you need to add more periods, you can add up to five heating periods on each schedule. To add a new heating period, tap one of the plus signs below.'],
      10: ['What time would you like this period to start?'],
      11: ['Time to set the temperature for this period.'],
      12: ['What time do you want the heating to turn off?'],
      13: ['What time would you like this period to end?'],
      14: ['What time would you like this period to start?'],
      15: ['Time to set the temperature for this period.'],
      16: ['What time would you like this period to end?']
    },
    // Computed
    summaryText: computed('textIndex', 'currentStep', 'line', function () {
      var index = this.get('textIndex'),
          step = this.get('currentStep'),
          line = this.get('line');

      if (step in index) {
        var lines = index[step];

        if (line !== null) {
          if (line in lines) {
            return [lines[line]];
          }
        } else {
          return lines;
        }
      }
      return [];
    })
  });
});