define('touch/components/mdl-checkbox', ['exports', 'ember-material-lite/components/mdl-checkbox'], function (exports, _mdlCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mdlCheckbox.default;
    }
  });
});