define('touch/routes/signup/error', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return this.modelFor("signup");
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('signup.password');
    },

    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      },
      backToStart: function backToStart() {
        this.transitionTo('signup.index');
      }
    }
  });
});