define('touch/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
       Make sure to define a factory for each model you want to create.
    */

    server.loadFixtures('users');
    server.loadFixtures('addresses');

    // get our current fixture user so we can set up gateways, schedules, holidays and stuff
    var users = server.schema.users.all(),
        user = users.models[0];

    server.create('gateway', 'isSetup',
    /*'isOnHoliday',*/
    {
      user: user
    });

    server.create('schedule', 'withWeekdayPeriods', {
      name: 'Monday',
      monday: true
    });
    server.create('schedule', 'withWeekdayPeriods', {
      name: 'Tuesday',
      tuesday: true
    });
    server.create('schedule', 'withWeekdayPeriods', {
      name: 'Wednesday',
      wednesday: true
    });
    server.create('schedule', 'withWeekdayPeriods', {
      name: 'Thursday',
      thursday: true
    });
    server.create('schedule', 'withWeekdayPeriods', {
      name: 'Friday',
      friday: true
    });
    server.create('schedule', 'withWeekendPeriods', {
      name: 'Saturday',
      saturday: true
    });
    server.create('schedule', 'withWeekendPeriods', {
      name: 'Sunday',
      sunday: true
    });

    server.create('device');

    // server.create('holidays', {
    //   name: 'Easter Holiday',
    //   startDate: '2018-03-28',
    //   endDate: '2018-04-02',
    //   startTime: '07:00',
    //   endTime: '15:00'
    // });
    // server.create('holiday', {
    //   name: 'Summer Holiday',
    //   startDate: '2018-06-16',
    //   endDate: '2018-06-30',
    //   startTime: '07:00',
    //   endTime: '15:00'
    // });
    // server.create('holiday', {
    //   name: 'Xmas Break',
    //   startDate: '2018-12-22',
    //   endDate: '2019-01-03',
    //   startTime: '07:00',
    //   endTime: '15:00'
    // });
  };
});