define('touch/components/signup-postcode-form', ['exports', 'ember-sauce-material-design/components/smd-form'], function (exports, _smdForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer;
  exports.default = _smdForm.default.extend({
    tagName: 'form',
    //classNames: ['smd-form', 'smd-form--external scrollable'],
    classNames: ['smd-form', 'smd-form--external'],
    addresses: null,
    _searchPostcode: function _searchPostcode() {
      var _this = this;

      var postcode = this.get('model.postcode'),
          url = window.getURL('/search-postcode');

      Ember.$.getJSON(url, {
        postcode: postcode
      }, function (response) {
        if (response) {
          _this.set('addresses', response.data);
        }
      });
    },
    // Actions
    actions: {
      selectAddress: function selectAddress(address) {
        this.set('model.line1', address.attributes.line1);
        this.set('model.line2', address.attributes.line2);
        this.set('model.town', address.attributes.line3);
        this.set('model.county', address.attributes.line4);
        this.set('model.postcode', address.attributes.postcode);
        this.sendAction();
      },
      enterManually: function enterManually() {
        this.sendAction();
      }
    },
    // Computed
    hasAddresses: computed.bool('addresses.length'),
    // Observer
    postcodeChanged: observer('model.postcode', function () {
      Ember.run.debounce(this, this._searchPostcode, 500);
    })
  });
});