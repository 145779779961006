define('touch/controllers/internal/home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isShowingModal: false,
    actions: {
      openModal: function openModal() {
        this.set('isShowingModal', true);
      },

      closeModal: function closeModal() {
        this.set('isShowingModal', false);
      }
    }
  });
});