define('touch/components/toggle-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['toggle__item'],
    classNameBindings: ['selectedClass', 'feelsClass'],
    value: null,
    selected: null,
    feels: null,
    // Computed
    selectedClass: computed('selected', 'value', function () {
      if (this.get('selected') === this.get('value')) {
        return 'toggle__item--selected';
      }
    }),
    feelsClass: computed('feels', function () {
      var feels = this.get('feels');
      if (feels) {
        return 'toggle__item--' + feels;
      }
    }),
    // Events
    click: function click() /*e*/{
      this.sendAction('action', this.get('value'));
    }
  });
});