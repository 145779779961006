define('touch/components/temperature-hero', ['exports', 'touch/mixins/holdable'], function (exports, _holdable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_holdable.default, {
    gatewayChannel: Ember.inject.service(),
    currentGatewayState: Ember.inject.service(),
    // Attributes
    classNames: ['temperature-hero'],
    classNameBindings: ['feelsClassName'],
    currentLabel: 'Room Temperature',
    targetLabel: 'Target Temp',
    disableControls: false,

    buttonTimeout: null,
    buttonWaitTimeout: null,

    // Computed
    showTargetTemperatureControls: computed('currentGatewayState.centralHeatingState', 'currentGatewayState.centralHeatingDisplayState', 'disableControls', function () {
      var state = this.get('currentGatewayState.centralHeatingState');
      var displayState = this.get('currentGatewayState.centralHeatingDisplayState');
      var disable = this.get('disableControls');

      if (disable || displayState == 'off') return false;

      return state == "auto" || state == "on" || displayState == "on";
    }),

    waitingForUpdate: computed('currentGatewayState.awaitingTargetTempUpdate', function () {
      return this.get('currentGatewayState.awaitingTargetTempUpdate');
    }),

    showOffTemperature: computed('currentGatewayState.centralHeatingTargetTemperature', 'currentGatewayState.centralHeatingState', function () {
      var state = this.get('currentGatewayState.centralHeatingState'),
          targetTemp = this.get('currentGatewayState.centralHeatingTargetTemperature');

      return state === "auto" && (targetTemp === null || targetTemp <= 10);
    }),

    feelsClassName: computed('currentGatewayState.feels', function () {
      var feels = this.get('currentGatewayState.feels');
      var disable = this.get('disableControls');

      if (disable) return;

      if (feels) {
        return 'temperature-hero--' + feels;
      }
    }),

    //flame: computed('currentGatewayState.centralHeatingDisplayState', 'currentGatewayState.centralHeatingTargetTemperature', 'currentGatewayState.centralHeatingCurrentTemperature', function() {
    flame: computed('currentGatewayState.flameSymbol', function () {
      return this._showFlameIcon();
    }),

    flameClassNames: computed('flame', function () {
      var classNames = ['temperature-hero__flame'];

      if (this.get('flame')) {
        classNames.push('temperature-hero__flame--on');
      }

      return classNames.join(' ');
    }),

    showHeatingTimer: Ember.computed('currentGatewayState.centralHeatingDisplayMode', function () {
      return this.get('currentGatewayState.centralHeatingDisplayMode') === 'timer' || this.get('currentGatewayState.centralHeatingDisplayMode') === 'next';
    }),

    heatingTimerEnd: Ember.computed('currentGatewayState.centralHeatingCountdownDisplayEnds', 'currentGatewayState.centralHeatingOverrideTimerEnds', function () {
      return this.get('currentGatewayState.centralHeatingCountdownDisplayEnds');
    }),

    showWaterTimer: Ember.computed('currentGatewayState.centralHeatingMode', function () {
      return false;
    }),

    showBoostState: Ember.computed('currentGatewayState.centralHeatingBoostActivated', function () {
      return this.get('currentGatewayState.centralHeatingBoostActivated') === 'true';
    }),

    // Private Logic
    _showFlameIcon: function _showFlameIcon() {
      //return this.get('currentGatewayState.centralHeatingDisplayState') !== 'off' && this.get('currentGatewayState.centralHeatingTargetTemperature') > this.get('currentGatewayState.centralHeatingCurrentTemperature');
      return this.get('currentGatewayState.flameSymbol') === 'on';
    },

    increaseTargetTemp: function increaseTargetTemp() {
      var targetTemp = this.get('currentGatewayState.centralHeatingTargetTemperature');

      if (this.okToChangeTargetTemp(targetTemp)) {
        targetTemp = this.maybeUpdateToClosestScheduledTemperature(targetTemp, -0.5);

        if (targetTemp < 30) {
          var newTargetTemp = targetTemp + 0.5;
          this.get('gatewayChannel').setTargetTemperature(newTargetTemp);
        }
      }
    },

    decreaseTargetTemp: function decreaseTargetTemp() {
      var targetTemp = this.get('currentGatewayState.centralHeatingTargetTemperature');

      if (this.okToChangeTargetTemp(targetTemp)) {
        targetTemp = this.maybeUpdateToClosestScheduledTemperature(targetTemp, 0.5);

        if (targetTemp > 10) {
          var newTargetTemp = targetTemp - 0.5;
          this.get('gatewayChannel').setTargetTemperature(newTargetTemp);
        }
      }
    },

    okToChangeTargetTemp: function okToChangeTargetTemp(targetTemp) {
      var centralHeatingState = this.get('currentGatewayState.centralHeatingState');

      return targetTemp !== null && targetTemp >= 10 || centralHeatingState === 'auto';
    },

    maybeUpdateToClosestScheduledTemperature: function maybeUpdateToClosestScheduledTemperature(existingTargetTemp, offsetAllowance) {
      var targetTemp = existingTargetTemp;

      if (targetTemp === null) {
        //Allow for -0.5 / 0.5 offset as we are about to increase it
        targetTemp = this.get('currentGatewayState.centralHeatingClosestScheduledTemperature') + offsetAllowance;
      }

      return targetTemp;
    },

    // Actions
    actions: {
      holdIncreaseTargetTemp: function holdIncreaseTargetTemp() {
        this.increaseTargetTemp();
        this.holdButton(this, "increaseTargetTemp");
      },
      releaseIncreaseTargetTemp: function releaseIncreaseTargetTemp() {
        this.clearTimeouts();
      },
      holdDecreaseTargetTemp: function holdDecreaseTargetTemp() {
        this.decreaseTargetTemp();
        this.holdButton(this, "decreaseTargetTemp");
      },
      releaseDecreaseTargetTemp: function releaseDecreaseTargetTemp() {
        this.clearTimeouts();
      }
    }
  });
});