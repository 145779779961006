define('touch/mirage/fixtures/gateways', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    serial: '0000-0000-0001',
    'address-line-1': '31-48 Queen Street',
    'address-line-2': 'C4DI',
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    userId: 1,
    fullySetup: true
  }, {
    serial: '0000-0000-0002',
    'address-line-1': '20 Westbourne Grove',
    'address-line-2': '',
    town: 'Hessle',
    county: 'East Yorkshire',
    postcode: 'HU13 0QL',
    userId: 2,
    fullySetup: true
  }];
});