define('touch/helpers/select-box-class', ['exports', 'ember-select-box/helpers/select-box-class'], function (exports, _selectBoxClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _selectBoxClass.default;
    }
  });
  Object.defineProperty(exports, 'selectBoxClass', {
    enumerable: true,
    get: function () {
      return _selectBoxClass.selectBoxClass;
    }
  });
});