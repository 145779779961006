define('touch/routes/internal/schedule/period', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _rollbackableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    // Services
    timeline: Ember.inject.service(),
    // Methods
    model: function model(params) {
      return this.store.find('period', params.period_id);
    },
    afterModel: function afterModel(period) {
      var timeline = Ember.get(this, 'timeline');
      timeline.goToPeriod(Ember.get(period, 'start'), Ember.get(period, 'end'));
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.schedule.index');
    }
  });
});