define('touch/routes/internal/schedules/heating', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Route = Ember.Route;
  exports.default = Route.extend(_backButtonable.default, {
    // Services
    header: inject.service('header-service'),
    gatewayChannel: inject.service(),
    timeline: inject.service(),
    // Methods
    activate: function activate() {
      Ember.get(this, 'gatewayChannel').connect();
      Ember.set(this, 'header.heading', 'Schedules');
      Ember.set(this, 'header.backRoute', null);
      Ember.set(this, 'header.backAction', null);
      Ember.set(this, 'header.actionRoute', 'internal.new-schedule.heating');
      Ember.set(this, 'header.actionText', 'New Schedule');
      Ember.get(this, 'timeline').reset();
    },
    model: function model() {
      return this.store.query('schedule', {});
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.home.index');
    }
  });
});