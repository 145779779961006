define('touch/controllers/internal/new-schedule/heating/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Actions
    actions: {
      next: function next() {
        return this.transitionToRoute('internal.new-schedule.heating.days');
      }
    }
  });
});