define('touch/adapters/application', ['exports', 'touch/config/environment', 'ember-data'], function (exports, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.rootApiURL,
    namespace: 'api/v1'
  });
});