define('touch/services/ember-cordova/events', ['exports', 'ember-cordova-events/services/ember-cordova/events'], function (exports, _events) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _events.default;
    }
  });
});