define('touch/components/type-svg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TypeComponent = Ember.Component.extend({
    tagName: ''
  });

  TypeComponent.reopenClass({
    positionalParams: ['path']
  });

  exports.default = TypeComponent;
});