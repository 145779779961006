define("touch/torii-providers/touch", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    // create a new authorization
    open: function open(options) {
      var postData = {
        format: "json-api",
        data: {
          type: "user",
          attributes: {
            email: options.get('email'),
            password: options.get('password')
          }
        }
      };

      return Ember.$.post(window.getURL("/sessions"), JSON.stringify(postData));
    }
  });
});