define('touch/controllers/internal/schedule/new-period', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toaster: Ember.inject.service('smd-toaster'),
    timeline: Ember.inject.service(),
    // Controllers
    scheduleController: Ember.inject.controller('internal.schedule'),
    // Actions
    actions: {
      save: function save(period) {
        var _this = this;

        var toaster = Ember.get(this, 'toaster');
        var timeline = Ember.get(this, 'timeline');

        period.save().then(function () {
          toaster.setMessage('Period Saved');
          timeline.reset();

          _this.transitionToRoute('internal.schedule');
        }, function () {
          Ember.get(_this, 'toaster').setMessage('Error Saving Period', {
            timeout: 5000
          });
        });
      }
    }
  });
});