define('touch/components/type-a', ['exports', 'ember-bem-sauce/mixins/bem-component', 'touch/mixins/colorable', 'touch/mixins/alignable'], function (exports, _bemComponent, _colorable, _alignable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_alignable.default, _bemComponent.default, _colorable.default, {
    // Attributes
    base: 'link',
    tagName: 'a',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'attributeBindings', ['href']);
    }
  });
});