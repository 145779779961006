define('touch/routes/internal/new-schedule/heating/finish', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    // Services
    header: Ember.inject.service('header-service'),
    // Methods
    activate: function activate() {
      this._super.apply(this, arguments);
      Ember.set(this, 'header.heading', 'Complete');
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // set new period flag to false
        controller.set('enableNewPeriods', false);
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.new-schedule.heating.period');
    },

    // Actions
    actions: {
      back: function back() {
        this.backButtonHandler();
      }
    }
    // actions: {
    //   editPeriod: function( /*period*/ ) {
    //     // currently not allowing period edits
    //   },
    //   addPeriod: function(period) {
    //     let length = this.get('scheduler.schedule.periods.length');
    //     if (length < 6) {
    //
    //       this.createExtraPeriod(period);
    //
    //       this.send('next');
    //     } else {
    //       Ember.Logger.log('too many periods ' + length);
    //     }
    //   },
    //   finish: function() {
    //     let schedule = this.get('scheduler.schedule');
    //     let periods = schedule.get('periods');
    //
    //     // This is bad m'kay
    //     schedule.save().then(
    //       (schedule) => {
    //
    //         let bulkSavePeriods = periods.map(
    //           (period) => {
    //             period.set('schedule', schedule);
    //             period.save();
    //           }
    //         );
    //         periods.forEach(
    //           (period) => {
    //             period.set('schedule', schedule);
    //             period.save();
    //           }
    //         );
    //
    //         Ember.RSVP.all(bulkSavePeriods).then(() => {
    //           this.get('toaster').setMessage('Schedule saved', {
    //             timeout: 5000
    //           });
    //           this.transitionTo('internal.schedules.heating');
    //         }, (rejectReason) => {
    //           Ember.Logger.log(rejectReason);
    //           if (rejectReason.status === 0) {
    //             this.get('toaster').setMessage('Failed to connect to server, please try again.', {
    //               timeout: 5000
    //             });
    //           } else {
    //             this.get('toaster').setMessage('Failed to set periods for this schedule, please try again.', {
    //               timeout: 5000
    //             });
    //           }
    //         });
    //       }, (error) => {
    //         this.get('toaster').setMessage(error.errors[0].detail, {
    //           timeout: 5000
    //         });
    //       });
    //   },
    // },
  });
});