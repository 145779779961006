define('touch/mixins/badge-support', ['exports', 'ember-sauce-material-design/mixins/badge-support'], function (exports, _badgeSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _badgeSupport.default;
    }
  });
});