define('touch/routes/internal/help/fault-view', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model(params) {
      return this.store.queryRecord('boiler-fault', { code: params.code });
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.help.index');
    }
  });
});