define('touch/components/toast-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    classNames: ['notice'],
    classNameBindings: ['modifierClass'],
    type: null, // 'primary' or 'error' supported
    text: '',
    icon: null,
    // Computed
    modifierClass: computed('type', function () {
      if (this.get('type')) {
        return 'notice--' + this.get('type');
      }
    }),
    iconClassNames: computed('type', function () {
      var classNames = ['material-icons', 'notice__icon'];
      if (this.get('type')) {
        classNames.push('notice__icon--' + this.get('type'));
      }
      return classNames.join(' ');
    }),
    iconName: computed('icon', 'type', function () {
      var icon = this.get('icon'),
          type = this.get('type');
      // do we have a custom icon?
      if (icon) {
        return icon;
      }
      // default icons for each type
      switch (type) {
        case 'primary':
          return 'flight_takeoff';
        case 'error':
          return 'warning';
        default:
          return 'watch_later';
      }
    })

  });
});