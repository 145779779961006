define('touch/routes/internal/new-schedule/heating/index', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    // Methods
    renderTemplate: function renderTemplate() {
      // skip the parent new-schedule.heating.hbs template
      // render straigh into the grandparent
      this.render('internal.new-schedule.heating.index', {
        into: 'internal.new-schedule'
      });
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo("internal.schedules.index");
    }
  });
});