define('touch/components/form-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      guidFor = Ember.guidFor;
  exports.default = Component.extend({
    // Attributes
    baseClass: 'list',
    id: null,
    classNames: [],
    classNameBindings: ['controlClass', 'noBorderClass'],
    model: null,
    value: null,
    name: null,
    type: 'text',
    label: null,
    tip: null,
    placeholder: null,
    didValidate: false,
    noBorder: false,
    // Computed
    isSelect: computed.equal('type', 'select'),
    isInvalid: computed.oneWay('validation.isInvalid'),
    showTip: computed.bool('tip'),
    showError: computed('validation.isDirty', 'isInvalid', 'didValidate', function () {
      return (this.get('validation.isDirty') || this.get('didValidate')) && this.get('isInvalid');
    }),
    controlClass: computed('baseClass', function () {
      return this.get('baseClass') + '__item';
    }),
    noBorderClass: computed('controlClass', 'noBorder', function () {
      if (this.get('noBorder')) {
        return this.get('controlClass') + '--no-border';
      }
    }),
    labelClasses: computed('baseClass', 'showError', 'showError', 'showTip', function () {
      var classNames = [];
      var baseClass = this.get('baseClass');
      classNames.push(baseClass + '__label');
      if (this.get('showError')) {
        classNames.push(baseClass + '__label--has-error');
      }
      if (this.get('showTip')) {
        classNames.push(baseClass + '__label--has-tip');
      }
      return classNames.join(' ');
    }),
    inputClasses: computed('baseClass', 'type', 'showError', 'showTip', function () {
      var classNames = ['list__input'];
      var type = this.get('type'),
          baseClass = this.get('baseClass');

      classNames.push(baseClass + '__input');

      classNames.push(baseClass + '__input--' + type);

      if (this.get('showError')) {
        classNames.push(baseClass + '__input--has-error');
      }
      if (this.get('showTip')) {
        classNames.push(baseClass + '__input--has-tip');
      }

      return classNames.join(' ');
    }),
    hasTip: computed.bool('tip'),
    errorClass: computed('baseClass', function () {
      return this.get('baseClass') + '__error';
    }),
    tipClass: computed('baseClass', function () {
      return this.get('baseClass') + '__tip';
    }),
    containerId: computed('id', function () {
      return this.get('id') || guidFor(this);
    }),
    inputId: computed('id', function () {
      return this.get('containerId') + '-input';
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      var propName = this.get('name');
      if (this.get("model")) {
        Ember.defineProperty(this, 'validation', computed.oneWay('model.validations.attrs.' + propName));
        Ember.defineProperty(this, 'value', computed.alias('model.' + propName));
      }
    },

    // Actions
    actions: {
      selectedOption: function selectedOption(option) {
        this.set('value', option);
      }
    },
    click: function click() {
      $('#' + this.get('inputId')).focus();
    }
  });
});