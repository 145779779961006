define('touch/routes/login', ['exports', 'touch/mixins/unauthable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _unauthableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthableRoute.default, _backButtonable.default, {
    toaster: Ember.inject.service('smd-toaster'),
    cookies: Ember.inject.service(),
    model: function model() {
      var cookieService = this.get('cookies');
      return this.store.createRecord('user', { email: cookieService.read('lastEmail') });
    },
    backButtonHandler: function backButtonHandler() {
      navigator.app.exitApp();
    },

    actions: {
      submit: function submit(model) {
        var _this = this;

        this.get('session').open('touch', model).then(function () /*response*/{
          var cookieService = _this.get('cookies');
          cookieService.write('lastEmail', model.get('email'), { maxAge: 31536000 });
          _this.get('toaster').setMessage('Welcome back', { timeout: 3000 });
          _this.router.transitionTo("internal.home");
        }, function (rejectReason) {
          switch (rejectReason.status) {
            case 0:
              _this.get('toaster').setMessage('Failed to connect to server, please try again.', { timeout: 5000 });
              break;
            case 418:
              _this.transitionTo('update-app');
              break;
            default:
              _this.get('toaster').setMessage('Login details are incorrect.', { timeout: 5000 });
          }
        });
      }
    }
  });
});