define('touch/components/external-link', ['exports', 'touch/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    tagName: 'a',
    href: null,

    // Events
    click: function click(e) {
      e.preventDefault();
      var fullUrl = '' + _environment.default.rootApiURL + Ember.get(this, 'href');
      window.open(fullUrl, '_system');
    }

  });
});