define('touch/controllers/internal/settings/data-privacy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    toaster: Ember.inject.service('smd-toaster'),
    gatewayChannel: Ember.inject.service(),
    errorChannel: Ember.inject.service(),
    userEmail: Ember.computed.alias('session.currentUser.email'),
    showConfirmDelete: false,
    isButtonDisabled: true,
    userEnteredPassword: function (value) {
      if (!Ember.isEmpty(value.password)) {
        this.set('isButtonDisabled', false);
      } else {
        this.set('isButtonDisabled', true);
      }
    }.observes("password"),
    actions: {
      exportUserData: function exportUserData() {
        var _this = this;

        var url = window.getURL('/export-user-data');
        Ember.$.ajax({
          type: "POST",
          url: url,
          success: function success() {
            _this.get('toaster').setMessage('Export Email Sent', {
              timeout: 5000
            });
          },
          error: function error(jqXHR, textStatus) {
            if (textStatus === 0) {
              _this.get('toaster').setMessage('Failed to connect to server, please try again.', {
                timeout: 5000
              });
            } else {
              _this.get('toaster').setMessage('Error exporting your user data', {
                timeout: 5000
              });
            }
          }
        });
      },
      showConfirmDelete: function showConfirmDelete() {
        this.toggleProperty('showConfirmDelete');
      },
      deleteAccount: function deleteAccount(password) {
        var _this2 = this;

        // TODO: find a more ember way of making the password part of a model
        var data = JSON.stringify({ "data": { "type": "user", "attributes": { "password": password } } });

        Ember.$.ajax({
          type: "POST",
          url: window.getURL('/delete-user-account'),
          accept: "application/vnd.api+json",
          contentType: "application/vnd.api+json",
          data: data,
          success: function success() {
            _this2.get('toaster').setMessage('User account deleted', {
              timeout: 5000
            });

            _this2.get('session').close().then(function () {
              _this2.get('gatewayChannel').leave();
              _this2.get('errorChannel').leave();
              _this2.get('store').unloadAll();
              _this2.get('toaster').setMessage("Goodbye...", {
                timeout: 3000
              });
              _this2.transitionToRoute('login');
            });
          },
          error: function error(jqXHR, textStatus) {
            if (textStatus === 0) {
              _this2.get('toaster').setMessage('Failed to connect to server, please try again.', {
                timeout: 5000
              });
            } else {
              _this2.get('toaster').setMessage('Error deleting user', {
                timeout: 5000
              });
            }
          }
        });
      }
    }
  });
});