define('touch/routes/internal/new-schedule/heating/days', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    // Services
    header: Ember.inject.service('header-service'),
    // Attributes
    title: 'Select Days',
    // Methods
    activate: function activate() {
      this._super.apply(this, arguments);
      Ember.set(this, 'header.heading', 'Select Days');
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo("internal.schedules.index");
    },

    actions: {
      back: function back() {
        this.transitionTo('internal.new-schedule.heating.index');
      }
    }
  });
});