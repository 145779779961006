define('touch/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    gateway: function gateway() {
      return _emberCliMirage.faker.random.uuid();
    },
    token: function token() {
      return _emberCliMirage.faker.random.uuid();
    },
    email: function email() {
      return _emberCliMirage.faker.internet.email();
    },
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    }
  });
});