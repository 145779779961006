define('touch/components/smd-app', ['exports', 'ember-sauce-material-design/components/smd-app'], function (exports, _smdApp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdApp.default;
    }
  });
});