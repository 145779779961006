define('touch/components/holiday-form', ['exports', 'touch/components/form-control', 'moment'], function (exports, _formControl, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _formControl.default.extend({
    // Attributes
    tagName: 'form',
    classNames: [''],
    hideSaveBtn: false,
    // Computed
    showDeleteButton: computed.bool('deleteAction'),
    minStartDate: computed(function () {
      return 'today';
    }),
    maxStartDate: computed('model.endDate', function () {
      var end = Ember.get(this, 'model.endDate');
      if (!Ember.isEmpty(end)) {
        return end;
      }
      return null;
    }),
    minEndDate: computed('model.startDate', function () {
      var start = Ember.get(this, 'model.startDate');
      if (!Ember.isEmpty(start)) {
        return start;
      }
      return 'today';
    }),
    maxEndDate: computed('model.startDate', function () {
      return (0, _moment.default)().add(10, 'years').format();
    }),
    // Methods
    momentFromDateAndTime: function momentFromDateAndTime(date, time) {
      var format = date + ' ' + time,
          datetime = (0, _moment.default)(format, 'YYYY-MM-DD HH:mm');
      if (datetime) {
        return datetime;
      }
    },
    startMoment: function startMoment() {
      var startDate = Ember.get(this, 'model.startDate'),
          startTime = Ember.get(this, 'model.startTime');
      return this.momentFromDateAndTime(startDate, startTime);
    },
    endMoment: function endMoment() {
      var endDate = Ember.get(this, 'model.endDate'),
          endTime = Ember.get(this, 'model.endTime');
      return this.momentFromDateAndTime(endDate, endTime);
    },

    // Actions
    actions: {
      save: function save() {
        if (this.validate()) {
          this.sendAction('saveAction', this.get('model'));
        }
      },
      delete: function _delete() {
        this.sendAction('deleteAction', this.get('model'));
      },
      /**
       * Set's the start time
       * ---
       * Before setting the start time we need to ensure the
       * new time is before the current start time
       */
      setStartTime: function setStartTime(format /*, time*/) {
        var startDate = Ember.get(this, 'model.startDate'),
            start = this.momentFromDateAndTime(startDate, format),
            end = this.endMoment();

        if (start.isBefore(end)) {
          Ember.set(this, 'model.startTime', format);
        }
      },

      /**
       * Set's the end time
       * ---
       * Before setting the end time we need to ensure the
       * new time is after the current start time
       */
      setEndTime: function setEndTime(format /*, time*/) {
        var endDate = Ember.get(this, 'model.endDate'),
            start = this.startMoment(),
            end = this.momentFromDateAndTime(endDate, format);

        if (start.isBefore(end)) {
          Ember.set(this, 'model.endTime', format);
        }
      },

      /**
       * Set's the start date
       * ---
       * Before setting the start date we need to ensure the
       * new date is before the current end date.
       * If they are on the same day, we also check times.
       * If startTime > endTime we also adjust the endTime so
       * they dont overlap
       */
      changeStartDate: function changeStartDate(dates, format /*, ref*/) {
        var _this = this;

        // This get's around the rendered twice flatpikr issue
        Ember.run.once(function () {

          // skip if we have deleted the model
          // this fixes an issue when rolling back attributes
          // on the new route
          if (Ember.get(_this, 'model.isDeleted')) {
            return;
          }

          var end = _this.endMoment(),
              start = _this.momentFromDateAndTime(format, Ember.get(_this, 'model.startTime'));

          if (!start.isBefore(end)) {
            // If times clash - auto adjust
            if (start.hour() < 23) {
              end.hour(start.hour() + 1);
              Ember.set(_this, 'model.endTime', end.format('HH:mm'));
            } else {
              start.hour(end.hour() - 1);
              Ember.set(_this, 'model.startTime', start.format('HH:mm'));
            }
          }

          Ember.set(_this, 'model.startDate', format);
        });
      },

      /**
       * Set's the end date
       * ---
       * Before setting the end date we need to ensure the
       * new date is after the current start date.
       * If they are on the same day, we also check times.
       * If startTime > endTime we also adjust the endTime so
       * they dont overlap
       */
      changeEndDate: function changeEndDate(dates, format /*, ref*/) {
        var _this2 = this;

        // This get's around the rendered twice flatpikr issue
        Ember.run.once(function () {

          // skip if we have deleted the model
          // this fixes an issue when rolling back attributes
          // on the new route
          if (Ember.get(_this2, 'model.isDeleted')) {
            return;
          }

          var start = _this2.startMoment(),
              end = _this2.momentFromDateAndTime(format, Ember.get(_this2, 'model.endTime'));

          if (!start.isBefore(end)) {
            // If times clash - auto adjust
            if (start.hour() < 23) {
              end.hour(start.hour() + 1);
              Ember.set(_this2, 'model.endTime', end.format('HH:mm'));
            } else {
              start.hour(end.hour() - 1);
              Ember.set(_this2, 'model.startTime', start.format('HH:mm'));
            }
          }

          Ember.set(_this2, 'model.endDate', format);
        });
      }
    }
  });
});