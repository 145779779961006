define('touch/components/schedule-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LinkComponent = Ember.LinkComponent,
      alias = Ember.computed.alias;
  exports.default = LinkComponent.extend({
    classNames: ['list__item'],
    classNameBindings: ['inActive:list__item--inactive'],
    inActive: alias('model.hasNoActiveDays'),
    didReceiveAttrs: function didReceiveAttrs() {
      var params = this.get('params');
      if (params) {
        params.unshift(null);
        this.set('params', params);
      }
      this._super.apply(this, arguments);
    }
  });
});