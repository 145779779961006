define('touch/routes/internal/settings/password', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _rollbackableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    toaster: Ember.inject.service('smd-toaster'),
    model: function model() {
      return this.store.createRecord('update-password');
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.settings');
    },

    actions: {
      changePassword: function changePassword() {
        var model = this.modelFor(this.routeName);
        if (model.get('validations.isValid')) {
          this.submitPasswordToServer(model);
        } else {
          this.get('toaster').setMessage('Please complete all fields fully', { timeout: 5000 });
        }
      }
    },

    submitPasswordToServer: function submitPasswordToServer(model) {
      var _this = this,
          url = window.getURL('/update-password'),
          data = JSON.stringify(model.serialize());

      Ember.$.ajax({
        type: "PUT",
        url: url,
        accept: "application/vnd.api+json",
        contentType: "application/vnd.api+json",
        data: data,
        success: function success(response) {
          var data = response.data;
          localStorage.setItem("auth_token", data.attributes.token);

          _this.get('toaster').setMessage('Password updated successfully', { timeout: 5000 });
          _this.set('session.currentUser.requiresNewPassword', false);
          _this.transitionTo('internal.home');
        },
        error: function error(jqXHR, textStatus, response) {
          if (textStatus === 0) {
            _this.get('toaster').setMessage('Failed to connect to server, please try again.', { timeout: 5000 });
          } else {
            _this.get('toaster').setMessage('Error resetting your password, please check your current password.', { timeout: 5000 });
          }
        }
      });
    }
  });
});