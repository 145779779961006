define('touch/routes/internal/settings/notifications', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return this.get('session.currentUser');
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.settings');
    }
  });
});