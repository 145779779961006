define('touch/helpers/to-date-range', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toDateRange = toDateRange;
  function toDateRange(params /*, hash*/) {
    var start = (0, _moment.default)(params[0]),
        end = (0, _moment.default)(params[1]);

    if (Ember.isEmpty(params[1])) {
      return start.format('Do MMM YYYY') + ' ongoing';
    }
    if (start.isSame(end, 'day')) {
      return '' + start.format('Do MMM YYYY');
    }
    if (start.isSame(end, 'month')) {
      return start.format('Do') + ' - ' + end.format('Do MMM YYYY');
    }
    if (start.isSame(end, 'year')) {
      return start.format('Do MMM') + ' - ' + end.format('Do MMM YYYY');
    }

    return start.format('Do MMM YYYY') + ' - ' + end.format('Do MMM YYYY');
  }

  exports.default = Ember.Helper.helper(toDateRange);
});