define('touch/routes/internal/holidays', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      goToHoliday: function goToHoliday(holiday) {
        return this.transitionTo('internal.holiday', holiday);
      },
      goToNew: function goToNew() {
        return this.transitionTo('internal.holidays.new');
      }
    }
  });
});