define('touch/components/legal-agreement', ['exports', 'ember-sauce-material-design/components/smd-form'], function (exports, _smdForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _smdForm.default.extend({
    isNextDisabled: Ember.computed.not('model.agreeTermsAt'),
    agreedToTerms: false,
    tagName: 'form',
    classNames: ['smd-form', 'smd-form--external scrollable'],
    didInsertElement: function didInsertElement() {
      this.$('.x-toggle-btn').addClass('needsclick');
    },

    actions: {
      setAgreeToTermsDate: function setAgreeToTermsDate() {
        this.toggleProperty('agreedToTerms');

        if (Ember.get(this, 'agreedToTerms')) {
          Ember.set(this, 'model.agreeTermsAt', new Date());
        } else {
          Ember.set(this, 'model.agreeTermsAt', null);
        }
      }
    }
  });
});