define('touch/components/period-start-form', ['exports', 'touch/utils/time-helpers'], function (exports, _timeHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    classNames: ['list__item', 'list__item--narrow', 'list__item--no-border'],
    error: null,
    tip: null,
    // Computed
    hasTip: computed.bool('tip'),
    hasError: computed.bool('error'),
    // Actions
    actions: {
      setStart: function setStart(target) {
        var value = target.value;
        var start = (0, _timeHelpers.timeToDecimal)(value);
        if (start <= 23.5) {
          this.set('model.start', start);
          this.set('error', null);
        } else {
          this.set('model.start', 23.5);
          this.set('error', 'Max start time 23:30');
          target.value = '23:30';
        }
      }
    }
  });
});