define('touch/routes/signup/postcode', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return this.modelFor('signup');
    },
    afterModel: function afterModel(model) {
      var userAgreementSet = Ember.get(model, 'agreeTermsAt');
      var userGatewaySet = Ember.get(model, 'gateway');

      if (Ember.isEmpty(userAgreementSet) || Ember.isEmpty(userGatewaySet)) {
        this.router.transitionTo('signup');
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('signup.gateway');
    },

    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      },
      submit: function submit() /*model*/{
        this.transitionTo('signup.address');
      }
    }
  });
});