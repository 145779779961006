define('touch/mirage/fixtures/address', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    line1: '42 Queen Street',
    line2: null,
    line3: null,
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: '42 Queen Street, Hull, East Yorkshire'
  }, {
    line1: '43 Queen Street',
    line2: null,
    line3: null,
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: '43 Queen Street, Hull, East Yorkshire'
  }, {
    line1: '44 Queen Street',
    line2: null,
    line3: null,
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: '44 Queen Street, Hull, East Yorkshire'
  }, {
    line1: 'C T I Corporate Travel International',
    line2: 'Wykeland House',
    line3: '47 Queen Street',
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'Kingston International Forwarding Ltd, Wykeland House, 47 Queen Street, Hull, East Yorkshire'
  }, {
    line1: 'Hatch',
    line2: 'Wykeland House',
    line3: '47 Queen Street',
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'Kingston International Forwarding Ltd, Wykeland House, 47 Queen Street, Hull, East Yorkshire'
  }, {
    line1: 'Language Is Everything Ltd',
    line2: '48 Queen Street',
    line3: null,
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'Language Is Everything Ltd, 48 Queen Street, Hull'
  }, {
    line1: 'Peter Dobbs Design & Print (Services)',
    line2: 'Wykeland House',
    line3: '47 Queen Street',
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'Peter Dobbs Design & Print (Services), Wykeland House, 47 Queen Street, Hull, East Yorkshire'
  }, {
    line1: 'Ruscadors',
    line2: '31-33 Queen Street',
    line3: null,
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'Ruscadors, 31-33 Queen Street, Hull, East Yorkshire'
  }, {
    line1: 'World Trade Centre Hull & Humber',
    line2: '48 Queen Street',
    line3: null,
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'World Trade Centre Hull & Humber, 48 Queen Street, Hull, East Yorkshire'
  }, {
    line1: 'Wykeland Ltd',
    line2: 'Wykeland House',
    line3: '47 Queen Street',
    town: 'Hull',
    county: 'East Yorkshire',
    postcode: 'HU1 1UU',
    text: 'Wykeland Ltd, Wykeland House, 47 Queen Street, Hull, East Yorkshire'
  }];
});