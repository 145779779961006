define('touch/mirage/fixtures/holidays', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    holidayId: 1,
    name: 'Greece Holiday',
    startDate: '2018-04-16',
    startTime: '07:00',
    endDate: '2018-04-29',
    endTime: '15:00'
  }];
});