define('touch/routes/signup/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('signup');
    },

    actions: {
      submit: function submit() {
        this.router.transitionTo('signup.gateway');
      },
      goBack: function goBack() {
        this.transitionTo('login');
      }
    }
  });
});