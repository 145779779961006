define('touch/mirage/factories/schedule', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    monday: function monday() {
      return false;
    },
    tuesday: function tuesday() {
      return false;
    },
    wednesday: function wednesday() {
      return false;
    },
    thursday: function thursday() {
      return false;
    },
    friday: function friday() {
      return false;
    },
    saturday: function saturday() {
      return false;
    },
    sunday: function sunday() {
      return false;
    },
    withWeekdayPeriods: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        server.create('period', {
          startTime: "06:30",
          endTime: "09:00",
          temperature: 21,
          schedule: schedule
        });
        server.create('period', {
          startTime: "16:30",
          endTime: "22:00",
          temperature: 23,
          schedule: schedule
        });
      }
    }),
    withWeekendPeriods: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        server.create('period', {
          startTime: "08:00",
          endTime: "11:00",
          temperature: 23,
          schedule: schedule
        });
        server.create('period', {
          startTime: "14:00",
          endTime: "23:45",
          temperature: 25,
          schedule: schedule
        });
      }
    })
  });
});