define('touch/components/scroll-to', ['exports', 'ember-scroll-to-mk2/components/scroll-to'], function (exports, _scrollTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _scrollTo.default;
    }
  });
});