define('touch/components/holiday-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['list'],
    actions: {
      selectItem: function selectItem(item) {
        this.sendAction('action', item);
      }
    }
  });
});