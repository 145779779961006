define('touch/mirage/serializers/application', ['exports', 'ember-cli-mirage', 'ember-inflector'], function (exports, _emberCliMirage, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.JSONAPISerializer.extend({
    keyForCollection: function keyForCollection(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    },
    keyForModel: function keyForModel(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    },
    typeKeyForModel: function typeKeyForModel(model) {
      return Ember.String.dasherize((0, _emberInflector.singularize)(model.modelName));
    },
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.dasherize(key);
    }
  });
});