define("touch/torii-adapters/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),

    open: function open(authorization) {
      var store = this.get('store');

      var data = authorization.data;

      localStorage.setItem("auth_token", data.attributes.token);
      localStorage.setItem("auth_user", data.id);
      localStorage.setItem("gateway_id", authorization.data.relationships.gateway.data.id);

      var userRecord;
      userRecord = store.peekRecord('user', data.id);

      if (!userRecord) {
        store.pushPayload(authorization);
      }
      return new Ember.RSVP.Promise(function (resolve) {
        var user = store.peekRecord('user', data.id);
        resolve({
          currentUser: user
        });
      });
    },

    fetch: function fetch() {
      var store = this.get('store');
      var cookieService = this.get('cookies');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (cookieService.read("impersonate_user")) {
          localStorage.setItem("auth_user", cookieService.read("impersonate_user"));
          localStorage.setItem("auth_token", cookieService.read("impersonate_token"));
          localStorage.setItem("gateway_id", cookieService.read("impersonate_gateway"));
        }

        if (localStorage.getItem("auth_token")) {
          // We only want to attempt to get the current user
          // if we have a token present
          Ember.$.ajax({
            url: window.getURL('/sessions'),
            success: function success(response) {
              if (response === null || response.data === null) {
                reject();
              } else {
                store.pushPayload(response);

                var user = store.peekRecord('user', response.data.id);
                resolve({
                  currentUser: user
                });
              }
            },
            error: Ember.run.bind(null, reject)
          });
        } else {
          reject();
        }
      });
    },

    close: function close() {

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: window.getURL('/sessions'),
          type: 'DELETE',
          success: Ember.run.bind(null, resolve),
          error: Ember.run.bind(null, reject)
        });
        resolve();
      }).then(function () {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_user");
        localStorage.removeItem("gateway_id");
        return;
      }).catch(function (error) {
        return Ember.RSVP.reject(error);
      });
    }

  });
});