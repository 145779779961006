define('touch/components/time-input', ['exports', 'ember-bem-sauce/mixins/bem-component', 'moment', 'touch/mixins/holdable'], function (exports, _bemComponent, _moment, _holdable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_holdable.default, _bemComponent.default, {
    base: 'timepicker',
    incIcon: 'arrow_drop_up',
    decIcon: 'arrow_drop_down',
    hourIncrement: 1,
    minuteIncrement: 15,
    secondIncrement: 0,
    timeFormat: 'HH:mm',
    minTime: null,
    maxTime: null,
    // Computed
    /**
     * Convert our time string into a moment instance
     **/
    date: Ember.computed('time', function () {
      var now = (0, _moment.default)().format('YYYY-MM-DD'),
          time = this.cleanStrangeValue(Ember.get(this, 'time'));

      return (0, _moment.default)(now + ' ' + time);
    }),
    hours: Ember.computed('date', function () {
      return Ember.get(this, 'date').format('HH');
    }),
    mins: Ember.computed('date', function () {
      return Ember.get(this, 'date').format('mm');
    }),
    secs: Ember.computed('date', function () {
      return Ember.get(this, 'date').format('ss');
    }),
    incrementTime: function incrementTime(unit) {
      var date = Ember.get(this, 'date').clone(),
          originalDate = Ember.get(this, 'date').clone(),
          incrementAmmount = Ember.get(this, unit + 'Increment'),
          action = Ember.get(this, 'onChange');

      var newDate = date.add(incrementAmmount, unit);

      newDate = this.checkDateIsOkForMaxTime(newDate, originalDate, Ember.get(this, 'maxTime'));

      newDate = this.nearestMinutes(15, newDate);

      if (action) {
        action(newDate.format(Ember.get(this, 'timeFormat')), newDate);
      }
    },
    decrementTime: function decrementTime(unit) {
      var date = Ember.get(this, 'date').clone(),
          originalDate = Ember.get(this, 'date').clone(),
          incrementAmmount = Ember.get(this, unit + 'Increment'),
          action = Ember.get(this, 'onChange');

      var newDate = date.subtract(incrementAmmount, unit);

      newDate = this.checkDateIsOkForMinTime(newDate, originalDate, Ember.get(this, 'minTime'));

      newDate = this.nearestMinutes(15, newDate);

      if (action) {
        action(newDate.format(Ember.get(this, 'timeFormat')), newDate);
      }
    },
    cleanStrangeValue: function cleanStrangeValue(timeValue) {
      return Ember.typeOf(timeValue) === 'undefined' || Ember.typeOf(timeValue) === 'null' ? '00:15' : timeValue.substring(0, 5);
    },
    stringToDatetime: function stringToDatetime(time) {
      var now = (0, _moment.default)().format('YYYY-MM-DD');
      return (0, _moment.default)(now + ' ' + time).toDate();
    },
    checkDateIsOkForMinTime: function checkDateIsOkForMinTime(newDate, existingDate, minTime) {
      if (minTime === null || minTime === undefined) return newDate;

      minTime = this.stringToDatetime(minTime);

      if (newDate >= minTime) return newDate;

      return existingDate;
    },
    checkDateIsOkForMaxTime: function checkDateIsOkForMaxTime(newDate, existingDate, maxTime) {
      if (maxTime === null || maxTime === undefined) return newDate;

      maxTime = this.stringToDatetime(maxTime);

      if (newDate <= maxTime) return newDate;

      return existingDate;
    },
    nearestMinutes: function nearestMinutes(interval, someMoment) {
      var roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
      return someMoment.clone().minute(roundedMinutes).second(0);
    },

    // Actions
    actions: {
      holdIncrement: function holdIncrement(unit) {
        this.incrementTime(unit);
        this.holdButton(this, 'incrementTime', unit);
      },
      holdDecrement: function holdDecrement(unit) {
        this.decrementTime(unit);
        this.holdButton(this, 'decrementTime', unit);
      },
      releaseChangeTime: function releaseChangeTime() {
        this.clearTimeouts();
      }
    }
  });
});