define('touch/services/current-gateway-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Service = Ember.Service;
  exports.default = Service.extend({
    // Services
    homeService: Ember.inject.service(),
    // Attributes
    centralHeatingDisplayState: null,
    centralHeatingDisplayMode: null,
    centralHeatingCountdownDisplayEnds: null,

    centralHeatingBoostActivated: null,
    centralHeatingState: null,
    centralHeatingMode: null,
    centralHeatingTimerMinutes: null,
    centralHeatingTimerEnds: null,
    centralHeatingNextPeriodStart: null,
    centralHeatingNextPeriodTargetTemperature: null,
    centralHeatingCurrentTemperature: null,
    centralHeatingTargetTemperature: null,
    centralHeatingOverrideTimerEnds: null,
    centralHeatingClosestScheduledTemperature: null,
    // optimisationState: false,
    flameSymbol: null,
    // isInOptimisationMode: false,
    holidayId: null,
    holidayEndsAt: null,
    gatewayState: null,
    isOffline: false,
    outsideTemperature: null,
    weatherType: null,

    awaitingTargetTempUpdate: false,

    // Methods
    syncStateFromHomeSocket: function syncStateFromHomeSocket() {
      var state = this.get('homeService.heatingState');
      var mode = this.get('homeService.heatingOverrideType');
      var time = this.get('homeService.heatingOverrideTime');

      this.set('centralHeatingState', state);
      this.set('centralHeatingMode', mode);
      this.set('centralHeatingDisplayState', state);
      this.set('centralHeatingDisplayMode', mode);
      this.set('centralHeatingTimerMinutes', time);
    },
    getStateForSocket: function getStateForSocket() {
      var _ref;

      Ember.Logger.log('CURRENT GATEWAY STATE = ' + this.get('optimisationState'));
      return _ref = {
        central_heating_display_state: this.get('centralHeatingDisplayState'),
        central_heating_display_mode: this.get('centralHeatingDisplayMode'),
        central_heating_state: this.get('centralHeatingState'),
        central_heating_mode: this.get('centralHeatingMode'),
        central_heating_boost_activated: this.get('centralHeatingBoostActivated'),
        central_heating_timer_minutes: this.get('centralHeatingTimerMinutes'),
        central_heating_timer_ends: this.get('centralHeatingTimerEnds'),
        central_heating_next_period_start: this.get('centralHeatingNextPeriodStart'),
        central_heating_next_period_end: this.get('centralHeatingNextPeriodEnd')
      }, _defineProperty(_ref, 'central_heating_next_period_end', this.get('centralHeatingNextPeriodTargetTemperature')), _defineProperty(_ref, 'central_heating_current_period_start', this.get('centralHeatingCurrentPeriodStart')), _defineProperty(_ref, 'central_heating_current_period_end', this.get('centralHeatingCurrentPeriodEnd')), _defineProperty(_ref, 'central_heating_current_temperature', this.get('centralHeatingCurrentTemperature')), _defineProperty(_ref, 'central_heating_target_temperature', this.get('centralHeatingTargetTemperature')), _defineProperty(_ref, 'central_heating_override_timer_ends', this.get('centralHeatingOverrideTimerEnds')), _defineProperty(_ref, 'central_heating_closest_schedule_temperature', this.get('centralHeatingClosestScheduledTemperature')), _defineProperty(_ref, 'optimisation_state', this.get('optimisationState')), _defineProperty(_ref, 'flame_symbol', this.get('flameSymbol')), _defineProperty(_ref, 'gateway_state', this.get('gatewayState')), _defineProperty(_ref, 'central_heating_countdown_display_ends', this.get('centralHeatingCountdownDisplayEnds')), _defineProperty(_ref, 'holiday_id', this.get('holidayId')), _defineProperty(_ref, 'holiday_ends_at', this.get('holidayEndsAt')), _ref;
    },
    // Computed
    feels: Ember.computed('centralHeatingCurrentTemperature', 'centralHeatingTargetTemperature', 'centralHeatingDisplayState', function () {
      var targetTemp = this.get('centralHeatingTargetTemperature');
      var heatingState = this.get('centralHeatingDisplayState');

      if (targetTemp > 0 && heatingState !== 'off') {
        if (targetTemp >= 25) {
          return 'hot';
        } else if (targetTemp >= 19) {
          return 'warm';
        } else {
          return 'cool';
        }
      }
      return '';
    }),
    displayTargetTemp: Ember.computed('centralHeatingTargetTemperature', 'centralHeatingDisplayState', function () {
      if (this.get('centralHeatingDisplayState') === 'off') {
        return null;
      } else {
        return this.get('centralHeatingTargetTemperature');
      }
    }),
    centralHeatingPeriodStart: Ember.computed('centralHeatingCurrentPeriodStart', 'centralHeatingNextPeriodStart', function () {
      if (Ember.isEmpty(this.get('centralHeatingCurrentPeriodStart'))) {
        return this.get('centralHeatingNextPeriodStart');
      } else {
        return this.get('centralHeatingCurrentPeriodStart');
      }
    }),
    centralHeatingPeriodEnd: Ember.computed('centralHeatingCurrentPeriodEnd', 'centralHeatingNextPeriodEnd', function () {
      if (Ember.isEmpty(this.get('centralHeatingCurrentPeriodEnd'))) {
        return this.get('centralHeatingNextPeriodEnd');
      } else {
        return this.get('centralHeatingCurrentPeriodEnd');
      }
    }),
    // Observers
    setHomeServiceHeatingState: Ember.observer('centralHeatingDisplayState', function () {
      this.set('homeService.heatingState', this.get('centralHeatingDisplayState'));
    }),
    setOptimisationStateBool: Ember.observer('optimisationState', function () {
      this.set('isInOptimisationMode', this.get('optimisationState') === 'on');
    }),
    setOptimisationState: Ember.observer('isInOptimisationMode', function () {
      Ember.Logger.log('optimisation observed ' + this.get('isInOptimisationMode'));
      if (this.get('isInOptimisationMode')) {
        this.set('optimisationState', 'on');
      } else {
        this.set('optimisationState', 'off');
      }
    }),
    setIsOffline: Ember.observer('gatewayState', function () {
      if (this.get('gatewayState') == 'down') {
        this.set('isOffline', true);
      } else {
        this.set('isOffline', false);
      }
    }).on('init')
    // observer gateway .oninit()
    // create modal service
    // inject into the full-screen-modal component
    // services holds the showing state
  });
});