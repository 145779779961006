define('touch/components/period-bar', ['exports', 'heating-timeline/components/period-bar'], function (exports, _periodBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _periodBar.default;
    }
  });
});