define('touch/controllers/internal/new-schedule/heating/days', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    // Actions
    actions: {
      saveDays: function saveDays(schedule) {
        // check we have a day selected
        if (Ember.get(schedule, 'hasNoActiveDays')) {
          Ember.get(this, 'toaster').setMessage('Please select 1 or more days for this schedule.');
          return;
        }
        this.transitionToRoute('internal.new-schedule.heating.period', {
          queryParams: {
            startTime: '06:00',
            endTime: '08:00',
            minTime: '00:00',
            maxTime: '23:45'
          }
        });
      }

    }
  });
});