define('touch/components/ideal-accordion-heading', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    action: 'setActiveIndex',
    activeIndex: null,
    index: null,
    title: null,
    classNames: ['accordion__heading'],
    classNameBindings: ['isActive:accordion__heading--active'],
    // Computed
    isActive: computed('activeIndex', 'index', function () {
      return this.get('activeIndex') === this.get('index');
    }),
    // Events
    click: function click(e) {
      e.preventDefault();
      this.set('activeIndex', this.get('index'));

      if (this.get('pressAction') !== undefined) {
        this.sendAction('pressAction');
      }
    }
  });
});