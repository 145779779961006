define('touch/controllers/internal/settings/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    toaster: Ember.inject.service('smd-toaster'),
    user: Ember.computed.alias('model'),
    actions: {
      save: function save(user) {
        var _this = this;

        user.save().then(function () {
          _this.get('toaster').setMessage('Accout details updated', { timeout: 2000 });
          _this.transitionToRoute('internal.settings');
        }, function (error) {
          _this.get('toaster').setMessage(error.errors[0].title, { timeout: 2000 });
        });
      }
    }
  });
});