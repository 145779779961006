define('touch/controllers/internal/new-schedule/heating/finish', ['exports', 'touch/mixins/controller/updates-schedule', 'touch/utils/time-helpers'], function (exports, _updatesSchedule, _timeHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_updatesSchedule.default, {
    // Services
    schedule: Ember.computed.alias('model'),
    toaster: Ember.inject.service('smd-toaster'),
    addPeriodButtonDisabled: Ember.computed('schedule.periods.[]', function () {
      return Ember.get(this, 'schedule.periods.length') >= 6;
    }),
    // Computed
    togglePeriodText: Ember.computed('enableNewPeriods', function () {
      if (Ember.get(this, 'enableNewPeriods')) {
        return 'Cancel';
      } else {
        return 'Add Period';
      }
    }),
    // Actions
    actions: {
      toggleAddPeriods: function toggleAddPeriods() {
        this.toggleProperty('enableNewPeriods');
      },
      save: function save(scheduleTemplate) {
        var _this = this;

        if (!this.validateSchedule(scheduleTemplate)) {
          return;
        }

        var toaster = Ember.get(this, 'toaster');

        this.updateSchedule(scheduleTemplate).then(function () {
          Ember.run.later(_this, function () {
            toaster.setMessage('New Schedule Saved');
            _this.transitionToRoute('internal.schedules.heating');
          }, 500);
        }, function () /*response*/{
          toaster.setMessage('Error Saving Schedule');
        });
      },
      addPeriod: function addPeriod(period) {
        if (!Ember.get(this, 'enableNewPeriods')) {
          return;
        }

        var startTime = (0, _timeHelpers.decimalToTime)(period.start),
            endTime = (0, _timeHelpers.decimalToTime)(period.end),
            reset = false;

        if (endTime === '24:00') {
          endTime = '23:45';
        }

        return this.transitionToRoute('internal.new-schedule.heating.period', {
          queryParams: {
            startTime: startTime,
            endTime: endTime,
            minTime: startTime,
            maxTime: endTime,
            reset: reset
          }
        });
      },
      editPeriod: function editPeriod(period) {
        return this.transitionToRoute('internal.new-schedule.heating.edit-period', period);
      }
    }
  });
});