define('touch/routes/internal/on-holiday', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    gatewayChannel: Ember.inject.service(),
    errorChannel: Ember.inject.service(),
    currentGatewayState: Ember.inject.service(),
    // Methods
    activate: function activate() {
      this.get('gatewayChannel').connect();
      this.get('errorChannel').connect();
    },
    model: function model(params) {
      return this.store.findRecord('holiday', params.holiday_id);
    },

    // Actions
    actions: {
      error: function error() /*reason, transition*/{
        // Probably a 404 if this route errors - send the bugger back
        return this.transitionTo('internal.home');
      }
    }
  });
});