define('touch/routes/internal/settings/data-privacy', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.settings');
    }
  });
});