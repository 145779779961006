define('touch/routes/internal/schedule/new-period', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _rollbackableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    timeline: Ember.inject.service(),
    // Attributes
    queryParams: {
      startTime: {
        refreshModel: true
      },
      endTime: {
        refreshModel: true
      },
      temp: {
        refreshModel: true
      }
    },
    // Methods
    model: function model(params) {
      // Get the schedule model from parent route and
      // related to this new period
      var scheduleController = this.controllerFor('internal.schedule'),
          schedule = Ember.get(scheduleController, 'model'),
          period = this.store.createRecord('period', {
        startTime: params.startTime,
        endTime: params.endTime,
        schedule: schedule
      }),
          timeline = Ember.get(this, 'timeline');

      timeline.goToPeriod(Ember.get(period, 'start'), Ember.get(period, 'end'));

      return period;
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.schedule.index');
    }
  });
});