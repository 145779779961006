define('touch/controllers/internal/schedule', ['exports', 'touch/utils/time-helpers'], function (exports, _timeHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Attributes
    enableNewPeriods: false,
    // Actions
    fixMaxTime: function fixMaxTime(emptyPeriod) {
      if (emptyPeriod.get('end') >= 24) {
        emptyPeriod.set('end', 23.75);
      }

      return emptyPeriod;
    },
    actions: {
      addPeriod: function addPeriod(period) {
        period = this.fixMaxTime(period);
        var startTime = (0, _timeHelpers.decimalToTime)(period.start),
            endTime = (0, _timeHelpers.decimalToTime)(period.end);
        return this.transitionToRoute('internal.schedule.new-period', {
          queryParams: {
            startTime: startTime,
            endTime: endTime
          }
        });
      },
      editPeriod: function editPeriod(period) {
        if (Ember.get(period, "id")) {
          return this.transitionToRoute('internal.schedule.period', period);
        }
      }
    }
  });
});