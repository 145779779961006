define('touch/routes/internal/help', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return Ember.RSVP.hash({
        helpItems: this.store.findAll('help-item'),
        boilerFaults: this.store.findAll('boiler-fault')
      });
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.home.index');
    }
  });
});