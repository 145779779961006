define('touch/mixins/smd-stackable-header-route', ['exports', 'ember-sauce-material-design/mixins/smd-stackabke-header-route'], function (exports, _smdStackabkeHeaderRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdStackabkeHeaderRoute.default;
    }
  });
});