define('touch/components/icon-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['icon-message'],
    classNameBindings: ['feelsClass'],
    icon: null,
    message: null,
    // Computed
    hasMessage: computed.bool('message'),
    iconPath: computed('icon', function () {
      return "icons/" + this.get('icon');
    }),
    feelsClass: computed('feels', function () {
      if (this.get('feels')) {
        return 'icon-message--' + this.get('feels');
      }
    })
  });
});