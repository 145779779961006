define('touch/mixins/controller/updates-schedule', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    validateSchedule: function validateSchedule(schedule) {
      var toaster = Ember.get(this, 'toaster');
      // Check this schedule has at least one day
      if (Ember.get(schedule, 'hasNoActiveDays')) {
        toaster.setMessage('Please select 1 or more days for this schedule.');
        return false;
      }
      // Check this schedule has at least one period
      if (Ember.get(schedule, 'periods.length') === 0) {
        toaster.setMessage('Schedules must have at least one heating period.');
        return false;
      }
      return true;
    },
    updateSchedule: function updateSchedule(scheduleTemplate) {
      var _this = this;

      Ember.Logger.log("updating schedules");
      Ember.Logger.log(scheduleTemplate);
      var promises = [];
      // Find collection of existing schedules for selected days
      var schedules = this.findSchedulesToUpdate(scheduleTemplate);
      Ember.Logger.log(schedules);
      // Delete the periods
      promises = this.clearSchedulePeriods(schedules, promises);
      // Clone and attach periods to these schedules
      //promises = this.copySchedulePeriods(scheduleTemplate, schedules, promises);
      Ember.Logger.log(promises);
      // Return bath promises
      //return all(promises);
      return Ember.RSVP.all(promises).then(function () {
        promises = [];
        // Clone and attach periods to these schedules
        promises = _this.copySchedulePeriods(scheduleTemplate, schedules, promises);
        // Return bath promises
        return Ember.RSVP.all(promises);
      });
    },


    // Methods
    findSchedulesToUpdate: function findSchedulesToUpdate(scheduleTemplate) {
      // array of properties to check
      var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],


      // find all schedules in the cache (there should be 8!)
      // one for each day + a new instance to the scheduleTemplate
      scheduleCache = this.store.peekAll('schedule');

      // Filter the cache to return existing schedules matching
      // days selected on this scheduleTemplate
      return scheduleCache.filter(function (schedule) {
        // check schedule has id - we dont want to return
        // the scheduleTemplate instance
        if (Ember.isEmpty(schedule.id)) {
          return;
        }
        // We don't need to do anything with the schedule that is being used
        // as the scheduleTemplate
        if (schedule.get('id') == scheduleTemplate.get('id')) {
          return;
        }
        var isActive = false;
        days.forEach(function (day) {
          // if this day is active for both the schedule
          // and template return it
          if (Ember.get(scheduleTemplate, day) && Ember.get(schedule, day)) {
            isActive = true;
          }
        });
        return isActive;
      });
    },
    clearSchedulePeriods: function clearSchedulePeriods(schedules, promises) {
      schedules.forEach(function (schedule) {
        var periods = schedule.get('periods');
        var length = periods.get('length');
        for (var i = 0; i <= length; i++) {
          var period = periods.objectAt(0);
          //schedule.get('periods').forEach((period) => {

          if (period !== undefined) {
            Ember.Logger.log(period);
            Ember.Logger.log('delete period ' + period.id + ' for schedule ' + schedule.id);
            //once(this, () => {
            period.set('schedule', null);
            period.deleteRecord();
            promises.pushObject(period.save());
            //});
          }
        } //);
      });
      return promises;
    },
    copySchedulePeriods: function copySchedulePeriods(scheduleTemplate, schedules, promises) {
      var _this2 = this;

      scheduleTemplate.get('periods').forEach(function (period) {
        // serialize the period data
        var periodData = period.toJSON();
        // loop over our schedules to be updated
        schedules.forEach(function (schedule) {
          Ember.Logger.log('save period ' + period.id + ' for schedule ' + schedule.id);
          //once(this, () => {
          var newPeriod = _this2.store.createRecord('period', periodData);
          newPeriod.set('schedule', schedule);
          promises.pushObject(newPeriod.save());
          //})
        });
      });
      // return the ember data promises
      return promises;
    }
  });
});