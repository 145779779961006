define('touch/components/period-label', ['exports', 'heating-timeline/components/period-label'], function (exports, _periodLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _periodLabel.default;
    }
  });
});