define('touch/routes/internal/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    activate: function activate() {
      return this.transitionTo('internal.home');
    }
  });
});