define('touch/components/type-page-body', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'type-page__body',
    tagName: 'div',
    // Methods
    init: function init() {
      var modifiers = new Ember.A(Ember.get(this, 'modifiers'));
      modifiers.addObjects(['noPadding:no-padding']);
      Ember.set(this, 'modifiers', modifiers);
      this._super.apply(this, arguments);
    }
  });
});