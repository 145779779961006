define('touch/models/user', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true)],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    requiresNewPassword: _emberData.default.attr('boolean'),
    receiveEmailAlerts: _emberData.default.attr('boolean'),
    receivePushAlerts: _emberData.default.attr('boolean'),
    agreeTermsAt: _emberData.default.attr('date'),
    gateway: _emberData.default.belongsTo()
  });
});