define('touch/components/masked-form-control', ['exports', 'ember-sauce-material-design/components/smd-form-control', 'ember-sauce-material-design/templates/components/smd-form-control'], function (exports, _smdFormControl, _smdFormControl2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _smdFormControl.default.extend({
    layout: _smdFormControl2.default,
    change: function change() {
      this.updateFieldBasedOnMask();
    },
    keyUp: function keyUp() {
      this.updateFieldBasedOnMask();
    },

    updateFieldBasedOnMask: function updateFieldBasedOnMask() {
      var inputValue = this.removeHyphensFromInput(this.get('value'));
      if (inputValue.length > 0) {
        inputValue = inputValue.match(new RegExp('.{1,4}', 'g')).join("-");
      }
      this.set('value', inputValue);
    },
    removeHyphensFromInput: function removeHyphensFromInput(value) {
      return value.split('-').join('');
    }
  });
});