define('touch/routes/signup', ['exports', 'touch/mixins/unauthable-route'], function (exports, _unauthableRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthableRoute.default, {
    model: function model() {
      return this.store.createRecord('signup');
    },

    actions: {
      goBack: function goBack() {
        this.transitionTo('signup');
      }
    }
  });
});