define('touch/components/ideal-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'button',
    classNames: ['button'],
    classNameBindings: ['primaryClass', 'altClass', 'dangerClass', 'squareClass', 'iconClass', 'borderlessClass', 'smallClass', 'whiteClass'],
    attributeBindings: ['disabled', 'type'],
    action: null,
    text: null,
    disabled: false,
    isPrimary: false,
    isAlt: false,
    isDanger: false,
    isSquare: false,
    isBorderless: false,
    isSmall: false,
    isWhite: false,
    icon: null,
    svg: null,
    right: false, // align icon to the right
    type: null,
    // Computed
    hasIcon: computed.bool('icon'),
    hasSvg: computed.bool('svg'),
    iconOrSvg: computed.or('hasIcon', 'hasSvg'),
    primaryClass: computed('isPrimary', function () {
      if (this.get('isPrimary')) {
        return 'button--primary';
      }
    }),
    altClass: computed('isAlt', function () {
      if (this.get('isAlt')) {
        return 'button--alt';
      }
    }),
    dangerClass: computed('isDanger', function () {
      if (this.get('isDanger')) {
        return 'button--danger';
      }
    }),
    squareClass: computed('isSquare', function () {
      if (this.get('isSquare')) {
        return 'button--square';
      }
    }),
    borderlessClass: computed('isBorderless', function () {
      if (this.get('isBorderless')) {
        return 'button--borderless';
      }
    }),
    iconClass: computed('iconOrSvg', 'right', function () {
      if (this.get('iconOrSvg')) {
        if (this.get('right')) {
          return 'button--icon button--icon-right';
        } else {
          return 'button--icon';
        }
      }
    }),
    smallClass: computed('isSmall', function () {
      if (this.get('isSmall')) {
        return 'button--small';
      }
    }),
    whiteClass: computed('isWhite', function () {
      if (this.get('isWhite')) {
        return 'button--white';
      }
    }),
    click: function click(e) {
      var action = Ember.get(this, 'onClick');

      if (action) {
        action();
      }
      if (this.get('action')) {
        this.sendAction('action', this);
        e.preventDefault();
      }
    }
  });
});