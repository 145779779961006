define('touch/services/full-screen-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      computed = Ember.computed;
  exports.default = Service.extend({
    errorMessage: null,
    failedTransition: null
  });
});