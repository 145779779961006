define('touch/controllers/internal/new-schedule/heating/period', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    // Attributes
    queryParams: ['startTime', 'endTime', 'minTime', 'maxTime', 'reset'],
    reset: false,
    startTime: '06:00',
    endTime: '08:00',
    maxTime: '23:45',
    minTime: '00:00',
    // Actions
    actions: {
      savePeriod: function savePeriod(period) {
        var _this = this;

        var toaster = Ember.get(this, 'toaster');

        period.save().then(function () {
          toaster.setMessage('Period Saved');
          _this.transitionToRoute('internal.new-schedule.heating.finish');
        }, function () {
          toaster.setMessage('Error Saving Period', {
            timeout: 5000
          });
        });
      },
      delete: function _delete(period) {
        var _this2 = this;

        var toaster = Ember.get(this, 'toaster'),
            timeline = Ember.get(this, 'timeline');

        // check this is not the last periods in the schedule
        if (period.get('schedule.periods.length') <= 1) {
          toaster.setMessage('You must have at least one period in a schedule', {
            timeout: 5000
          });
          return;
        }

        period.deleteRecord();
        period.save().then(function () {
          toaster.setMessage('Period removed');
          timeline.reset();
          _this2.transitionToRoute('internal.schedule');
        }, function () /*error*/{
          period.rollbackAttributes();
          toaster.setMessage('Failed to remove period', {
            timeout: 5000
          });
        });
      }
    }
  });
});