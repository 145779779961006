define('touch/components/boiler-settings-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    optimisationState: null,

    tagName: 'div',
    classNames: ['list'],

    didInsertElement: function didInsertElement() {
      this.$('.x-toggle-btn').addClass('needsclick');
    },


    actions: {
      setOptimisationState: function setOptimisationState() {
        var action = Ember.get(this, 'onSave');
        if (action) {
          action();
        }
      }
    }
  });
});