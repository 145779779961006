define('touch/routes/internal/new-schedule/heating/period', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    // Services
    header: Ember.inject.service('header-service'),
    // Attributes
    queryParams: {
      startTime: {
        refreshModel: true
      },
      endTime: {
        refreshModel: true
      },
      maxTime: {
        refreshModel: true
      },
      minTime: {
        refreshModel: true
      },
      reset: {
        refreshModel: true
      }
    },
    // Methods
    activate: function activate() {
      this._super.apply(this, arguments);
      Ember.set(this, 'header.heading', 'Period');
    },
    model: function model(params /*, transition*/) {
      // Get the schedule model from parent route and
      // related to this new period
      var scheduleController = this.controllerFor('internal.new-schedule.heating'),
          schedule = Ember.get(scheduleController, 'model'),
          periodReset = params.reset;

      // if reset is true delete periods
      if (periodReset) {
        // remove the last period from schedule
        schedule.get('periods').popObject();
      }

      var period = this.store.createRecord('period', {
        startTime: params.startTime,
        endTime: params.endTime,
        schedule: schedule
      });

      return period;
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        // controller.set('startTime', '06:00');
        // controller.set('endTime', '08:00');
        // controller.set('maxTime', '23:45');
        // controller.set('minTime', '00:00');
        controller.set('reset', false);
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.schedule.index');
    },

    // Actions
    actions: {
      back: function back() {
        return this.transitionTo('internal.new-schedule.heating.days');
      }
    }
  });
});