define('touch/components/day-picker-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend({
    // Services
    toaster: inject.service('smd-toaster'),
    // Attributes
    tagName: 'li',
    classNames: ['day-picker__day'],
    classNameBindings: ['indeterminateClass', 'checkedClass'],
    text: null,
    title: null,
    value: null,
    indeterminate: false,
    // Computed
    indeterminateClass: computed('indeterminate', function () {
      if (this.get('indeterminate')) {
        return 'day-picker__day--indeterminate';
      }
    }),
    checkedClass: computed('value', function () {
      if (this.get('value')) {
        return 'day-picker__day--checked';
      }
    }),
    // Events
    click: function click(e) {
      e.preventDefault();

      if (this.get('disabled') || !this.get('indeterminate')) {
        this.get('toaster').setMessage('You are editing ' + this.get('title') + '\'s schedule and cannot deselect this day.', {
          timeout: 5000
        });
        return;
      }

      var value = !this.get('value');

      this.set('value', value);
      this.get('onchange')(value);
    }
  });
});