define('touch/controllers/internal/schedule/index', ['exports', 'touch/mixins/controller/updates-schedule', 'touch/utils/time-helpers'], function (exports, _updatesSchedule, _timeHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_updatesSchedule.default, {
    // Services
    schedule: Ember.computed.alias('model'),
    toaster: Ember.inject.service('smd-toaster'),
    // Controllers
    scheduleController: Ember.inject.controller('internal.schedule'),
    // Attributes
    isApplyDisabled: true,
    // Computed
    addPeriodButtonDisabled: Ember.computed('schedule.periods.[]', function () {
      return Ember.get(this, 'schedule.periods.length') >= 6;
    }),
    enableNewPeriods: Ember.computed.alias('scheduleController.enableNewPeriods'),
    togglePeriodText: Ember.computed('enableNewPeriods', function () {
      if (Ember.get(this, 'enableNewPeriods')) {
        return 'Cancel';
      } else {
        return 'Add Period';
      }
    }),
    // Methods
    /**
     * This stinks! Do we need it still?
     * If so explain why
     */
    fixMaxTime: function fixMaxTime(emptyPeriod) {
      if (emptyPeriod.get('end') >= 24) {
        emptyPeriod.set('end', 23.75);
      }
      return emptyPeriod;
    },
    // Actions
    actions: {
      addPeriod: function addPeriod(period) {
        period = this.fixMaxTime(period);
        var startTime = (0, _timeHelpers.decimalToTime)(period.start),
            endTime = (0, _timeHelpers.decimalToTime)(period.end);
        return this.transitionToRoute('internal.schedule.new-period', {
          queryParams: {
            startTime: startTime,
            endTime: endTime
          }
        });
      },
      editPeriod: function editPeriod(period) {
        if (Ember.get(period, "id")) {
          return this.transitionToRoute('internal.schedule.period', period);
        }
      },
      onDayPickerChange: function onDayPickerChange(days) {
        var totalSelected = Object.values(days).filter(Boolean).length;

        if (totalSelected) {
          Ember.set(this, 'isApplyDisabled', false);
        } else {
          Ember.set(this, 'isApplyDisabled', true);
        }
      },
      toggleAddPeriods: function toggleAddPeriods() {
        this.toggleProperty('enableNewPeriods');
      },
      save: function save(schedule) {
        var _this = this;

        Ember.set(this, 'isApplyDisabled', true);

        if (!this.validateSchedule(schedule)) {
          Ember.set(this, 'isApplyDisabled', false);
          return;
        }

        var toaster = Ember.get(this, 'toaster');

        this.updateSchedule(schedule).then(function () {
          Ember.run.later(_this, function () {
            toaster.setMessage('Schedule Updated');
            _this.transitionToRoute('internal.schedules.heating');
          }, 500);
        }, function () /*response*/{
          toaster.setMessage('Error Saving Schedule');
        });
      }
    }
  });
});