define('touch/models/gateway', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    // Attributes
    serial: attr('string'),
    addressLine1: attr('string'),
    addressLine2: attr('string'),
    town: attr('string'),
    county: attr('string'),
    postcode: attr('string'),
    fullySetup: attr('boolean'),
    devicesData: attr(),
    // Relationships
    user: belongsTo(),
    currentHoliday: belongsTo('holiday', {
      inverse: null
    })
  });
});