define('touch/helpers/smd-subheader', ['exports', 'ember-sauce-material-design/helpers/smd-subheader'], function (exports, _smdSubheader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdSubheader.default;
    }
  });
  Object.defineProperty(exports, 'smdSubheader', {
    enumerable: true,
    get: function () {
      return _smdSubheader.smdSubheader;
    }
  });
});