define('touch/routes/signup/email', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return this.modelFor('signup');
    },
    afterModel: function afterModel(model) {
      var userAgreementSet = Ember.get(model, 'agreeTermsAt');
      var userGatewaySet = Ember.get(model, 'gateway');
      var userAddressSet = Ember.get(model, 'line1');
      var userAddressTownSet = Ember.get(model, 'town');

      if (Ember.isEmpty(userAgreementSet) || Ember.isEmpty(userGatewaySet) || Ember.isEmpty(userAddressSet) || Ember.isEmpty(userAddressTownSet)) {
        this.router.transitionTo('signup');
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('signup.address');
    },

    toaster: Ember.inject.service('smd-toaster'),
    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      },
      submit: function submit(model) {
        var _this = this;

        var data = JSON.stringify({
          data: {
            type: 'user',
            attributes: {
              email: model.get('email')
            }
          }
        });

        Ember.$.post(window.getURL('/check-email'), data).done(function () {
          _this.router.transitionTo('signup.name');
        }).fail(function () /*response*/{
          _this.get('toaster').setMessage('That email address is already registered.', { timeout: 5000 });
        });
      }
    }
  });
});