define('touch/routes/internal/new-schedule/heating/edit-period', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _rollbackableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    // Services
    header: Ember.inject.service('header-service'),
    // Attributes
    queryParams: {
      startTime: {
        refreshModel: true
      },
      endTime: {
        refreshModel: true
      }
    },
    // Methods
    activate: function activate() {
      this._super.apply(this, arguments);
      Ember.set(this, 'header.heading', 'Edit Period');
    },

    model: function model(params /*, transition*/) {
      return this.store.find('period', params.period_id);
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo("internal.new-schedule.heating.finish");
    },

    // Actions
    actions: {
      back: function back() {
        return this.transitionTo('internal.new-schedule.heating.finish');
      }
    }
  });
});