define('touch/components/ideal-accordion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    activeIndex: null,
    classNames: ['accordion'],
    title: null
  });
});