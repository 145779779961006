define('touch/components/period-end-form', ['exports', 'touch/utils/time-helpers'], function (exports, _timeHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    classNames: ['list__item', 'list__item--narrow', 'list__item--no-border'],
    error: null,
    tip: null,
    // Computed
    hasTip: computed.bool('tip'),
    hasError: computed.bool('error'),
    // Actions
    actions: {
      setEnd: function setEnd(target) {
        var value = target.value;
        var end = (0, _timeHelpers.timeToDecimal)(value);
        var start = this.get('model.start') + 0.25;

        if (end <= start) {
          var startTime = (0, _timeHelpers.decimalToTime)(start);
          this.set('error', 'Min end time ' + startTime);
          target.value = startTime;
        } else if (end > 23.75) {
          this.set('model.end', 23.75);
          this.set('error', 'Max end time 23:45');
          target.value = '23:45';
        } else {
          // All good!
          this.set('model.end', end);
          this.set('error', null);
        }
      }
    }
  });
});