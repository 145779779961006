define('touch/routes/internal/schedules/water', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Route = Ember.Route;
  exports.default = Route.extend({
    // Services
    header: inject.service('header-service'),
    // Methods
    activate: function activate() {
      this.set('header.heading', 'Schedules');
      this.set('header.backRoute', 'internal.home');
    },
    model: function model() {
      return this.store.findAll('schedule');
    }
  });
});