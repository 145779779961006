define('touch/routes/update-app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    isIOS: false,
    isAndroid: false,

    beforeModel: function beforeModel() {
      if (window.cordova !== undefined) {
        if (window.device.platform.toLowerCase() == "ios") {
          this.set('isIOS', true);
        } else if (window.device.platform.toLowerCase() == "android") {
          this.set('isAndroid', true);
        }
      }
    }
  });
});