define('touch/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    this.transition(this.fromRoute('login'), this.toRoute('signup'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('signup.index'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('signup.gateway'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('signup.postcode'), this.toRoute('signup.address'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('signup.address'), this.toRoute('signup.email'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('signup.email'), this.toRoute('signup.name'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('signup.name'), this.toRoute('signup.password'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('signup.password'), this.toRoute('signup.setting-up'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('signup.setting-up'), this.toRoute('signup.completed'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('signup.setting-up'), this.toRoute('signup.error'), this.use('toLeft'), this.reverse('toRight'));

    /*
    * Internal
    */
    this.transition(this.fromRoute(/internal\.([a-z]*)/), this.use('crossFade', { duration: 250 }));

    this.transition(this.fromRoute('internal.settings.index'), this.toRoute(/internal\.settings\.([a-z]*)/), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.settings.account'), this.toRoute('internal.settings.password'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.schedules'), this.toRoute('internal.schedule'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.schedules'), this.toRoute('internal.new-schedule'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.new-schedule.heating.days'), this.toRoute('internal.new-schedule.heating.period'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.new-schedule.heating.period'), this.toRoute('internal.new-schedule.heating.finish'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.holidays.index'), this.toRoute(/internal\.holidays\.([a-z]*)/), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('internal.holidays'), this.toRoute('internal.holiday'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.hasClass('temp-controls'),

    // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('mode-fade', { duration: 500 }),

    // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('mode-fade', { duration: 500 }));
    /*this.transition(
      this.hasClass('temp-controls'),
       // this makes our rule apply when the liquid-if transitions to the
      // true state.
      this.toValue(false),
      this.use('fade', { duration: 300 }),
       // which means we can also apply a reverse rule for transitions to
      // the false state.
      //this.reverse('fade', { duration: 10000})
    );*/

    /*
     * Home
     */
    // this.transition(
    //   this.fromRoute('internal.home.index'),
    //   this.toRoute('internal.home.water'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );
    // this.transition(
    //   this.fromRoute('internal.home.index'),
    //   this.toRoute('internal.home.combi'),
    //   this.use('toUp'),
    //   this.reverse('toDown')
    // );

    // /*
    //  * Holidays
    //  */
    // this.transition(
    //   this.fromRoute('internal.home'),
    //   this.toRoute('internal.holidays'),
    //   this.use('toUp'),
    //   this.reverse('toDown')
    // );
    // this.transition(
    //   this.fromRoute('internal.holidays.index'),
    //   this.toRoute('internal.holidays.new'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );
    // this.transition(
    //   this.fromRoute('internal.holidays'),
    //   this.toRoute('internal.holiday'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // /*
    //  * Settings
    //  */
    // this.transition(
    //   this.fromRoute('internal.home'),
    //   this.toRoute('settings'),
    //   this.use('toUp'),
    //   this.reverse('toDown')
    // );

    // this.transition(
    //   this.fromRoute('internal.settings.index'),
    //   this.toRoute(/^internal\.settings/),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.settings.account'),
    //   this.toRoute('internal.settings.password'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.help.index'),
    //   this.toRoute('internal.help.general-view'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );
    // this.transition(
    //   this.toRoute('internal.home'),
    //   this.toRoute('internal.help.general-view'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.help.index'),
    //   this.toRoute('internal.help.fault-view'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );
    // this.transition(
    //   this.toRoute('internal.home'),
    //   this.toRoute('internal.help.fault-view'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // /*
    //  * Schedules
    //  */
    // this.transition(
    //   this.fromRoute('internal.schedules.heating'),
    //   this.toRoute('internal.schedules.water'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.schedules'),
    //   this.toRoute('internal.schedule'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.schedule.index'),
    //   this.toRoute('internal.schedule.period'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.schedule.index'),
    //   this.toRoute('internal.schedule.new-period'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );


    // /*
    //  * New Schedule wizard
    //  */
    // this.transition(
    //   this.fromRoute('internal.schedules.heating'),
    //   this.toRoute('internal.new-schedule.heating.index'),
    //   this.use('toUp'),
    //   this.reverse('toDown')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule'),
    //   this.toRoute('internal.new-schedule.heating.days'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule.heating.days'),
    //   this.toRoute('internal.new-schedule.heating.start'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule.heating.start'),
    //   this.toRoute('internal.new-schedule.heating.temp'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule.heating.temp'),
    //   this.toRoute('internal.new-schedule.heating.end'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule.heating.end'),
    //   this.toRoute('internal.new-schedule.heating.start'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule.heating.end'),
    //   this.toRoute('internal.new-schedule.heating.name'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );

    // this.transition(
    //   this.fromRoute('internal.new-schedule.heating.name'),
    //   this.toRoute('internal.new-schedule.heating.finish'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );


    // this.transition(
    //   this.matchSelector('.override__modal'),
    //   this.toValue((toValue, fromValue) => toValue !== null && fromValue === null),
    //   this.use('toUp')
    // );
    /*this.transition(
      this.matchSelector('.override__modal'),
      this.toValue((toValue, fromValue) => toValue === null && fromValue !== null),
      this.use('toDown')
    );*/
  };
});