define('touch/components/state-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    tagName: 'div',
    classNames: ['toggle'],
    state: null,
    feels: null,
    // Computed
    options: computed('_options', 'state', function () {
      var _this = this;

      var array = [];
      this.get('_options').forEach(function (state) {
        var obj = {
          label: state.label,
          value: state.value,
          classNames: ''
        };
        if (_this.get('state') === state.value) {
          obj.classNames = "toggle__item--selected";
        }
        array.push(obj);
      });

      return array;
    }),
    // Actions
    actions: {
      togglePress: function togglePress(state) {
        this.sendAction('action', state);
      }
    },
    // Private Attributes
    _options: [{
      label: 'Off',
      value: 'off',
      classNames: ''
    }, {
      label: 'Manual',
      value: 'on',
      classNames: ''
    }, {
      label: 'Auto',
      value: 'auto',
      classNames: ''
    }]
  });
});