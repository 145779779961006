define('touch/models/schedule', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var computed = Ember.computed;
  exports.default = Model.extend(Validations, {
    name: attr('string'),
    monday: attr('boolean', {
      defaultValue: false
    }),
    tuesday: attr('boolean', {
      defaultValue: false
    }),
    wednesday: attr('boolean', {
      defaultValue: false
    }),
    thursday: attr('boolean', {
      defaultValue: false
    }),
    friday: attr('boolean', {
      defaultValue: false
    }),
    saturday: attr('boolean', {
      defaultValue: false
    }),
    sunday: attr('boolean', {
      defaultValue: false
    }),
    periods: hasMany('period'),
    // Computed
    daysOfWeekArray: computed('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', function () {
      var days = [];

      if (this.get('monday')) {
        days.push('Mon');
      }
      if (this.get('tuesday')) {
        days.push('Tue');
      }
      if (this.get('wednesday')) {
        days.push('Wed');
      }
      if (this.get('thursday')) {
        days.push('Thu');
      }
      if (this.get('friday')) {
        days.push('Fri');
      }
      if (this.get('saturday')) {
        days.push('Sat');
      }
      if (this.get('sunday')) {
        days.push('Sun');
      }

      return days;
    }),
    daysOfWeek: computed('daysOfWeekArray', function () {
      return this.get('daysOfWeekArray').join(' ');
    }),
    hasNoActiveDays: computed('daysOfWeekArray', function () {
      return this.get('daysOfWeekArray').length === 0;
    })
  });
});