define('touch/components/form-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    // Atributes
    tagName: 'form',
    model: null,
    errorMessage: 'Please correct the errors in the form',
    childComponent: 'form-input',
    // Services
    toaster: service('smd-toaster'),
    // Computed
    childFormControls: Ember.computed('childComponent', 'childViews', function () {
      var childViews = this.get('childViews'),
          childComponentName = this.get('childComponent');
      if (childViews) {
        var toReturn = childViews.filter(function (currentChildView) {
          return currentChildView.constructor.toString().indexOf(childComponentName) !== -1;
        });
        return toReturn;
      }
    }),
    validate: function validate() {
      var errorMessages = [];

      var formControls = this.get('childFormControls');

      formControls.forEach(function (formControl) {
        if (formControl.get('validation') && !formControl.get('validation.isValid')) {
          errorMessages.addObjects(formControl.get('validation.errors').mapBy('message'));
        }
        formControl.set('didValidate', true);
      });

      if (errorMessages.length !== 0) {
        var errorMessage = errorMessages.join(', ');

        this.get('toaster').setMessage(this.get('errorMessage'), { timeout: 5000 });

        Ember.Logger.log("Errors: " + errorMessage);

        return false;
      }
      return true;
    },
    // Events
    submit: function submit(e) {
      e.preventDefault();
      if (this.validate()) {
        this.sendAction('action', this.get('model'));
      }
    }
  });
});