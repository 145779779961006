define('touch/services/heating-scheduler', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Service.extend({
    schedule: null,
    activePeriod: null,
    step: 0,
    lastTemp: 22,
    baseRoute: 'internal.new-schedule.heating.',
    routeIndex: {
      0: 'index',
      1: 'days',
      2: 'period',
      3: 'temp',
      4: 'end',
      5: 'start',
      6: 'temp',
      7: 'end',
      8: 'name',
      9: 'finish',
      10: 'start',
      11: 'temp',
      12: 'end',
      13: 'finish',
      14: 'start',
      15: 'temp',
      16: 'end',
      17: 'finish',
      18: 'start',
      19: 'temp',
      20: 'end',
      21: 'finish'
    },
    morningPeriodDefaults: {
      start: 6.5,
      end: 9,
      temp: 22
    },
    afternoonPeriodDefaults: {
      start: 16,
      end: 22.5,
      temp: 22
    },
    // Computed
    hasActiveSchedule: computed.bool('schedule'),
    hasActivePeriod: computed.bool('activePeriod'),
    startRoute: computed('routeIndex', 'baseRoute', function () {
      var index = this.get('routeIndex'),
          base = this.get('baseRoute');

      return base + index[0];
    }),
    nextRoute: computed('routeIndex', 'baseRoute', 'step', function () {
      var index = this.get('routeIndex'),
          base = this.get('baseRoute'),
          step = parseInt(this.get('step')) + 1;

      return base + index[step];
    }),
    prevRoute: computed('routeIndex', 'baseRoute', 'step', function () {
      var index = this.get('routeIndex'),
          base = this.get('baseRoute'),
          step = parseInt(this.get('step')) - 1;

      return base + index[step];
    }),
    smartName: computed('schedule', function () {
      var days = this.get('schedule.daysOfWeekArray');

      if (days.length === 0) {
        return 'Not Active';
      }
      if (days.length === 7) {
        return 'Every Day';
      }
      if ((0, _lodash.isEqual)(days, ['Sat', 'Sun'])) {
        return 'Every Weekend';
      }
      if ((0, _lodash.isEqual)(days, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'])) {
        return 'Every Weekday';
      }
      return days.join(', ');
    }),
    // Methods
    startNewSchedule: function startNewSchedule(schedule) {
      this.set('schedule', schedule);
      this.set('activePeriod', null);
      this.set('step', 0);
    },
    startNewPeriod: function startNewPeriod(period) {
      var schedule = this.get('schedule');
      schedule.get('periods').pushObject(period);
      this.set('activePeriod', period);
    },
    nextStep: function nextStep() {
      var step = parseInt(this.get('step'));
      if (step < 21) {
        step = step + 1;
      }
      this.set('step', step);
      return step;
    },
    prevStep: function prevStep() {
      var step = parseInt(this.get('step'));
      if (step > 0) {
        step = step - 1;
      }
      this.set('step', step);
      return step;
    },
    rollbackStep: function rollbackStep() {
      var currentStep = parseInt(this.get('step'));

      Ember.Logger.log('rollbackStep ' + currentStep);

      // 2 5 10 14 18
      if (currentStep === 2) {
        this.rollbackPeriodAtIndex(0);
      }
      if (currentStep === 5) {
        this.rollbackPeriodAtIndex(1);
      }
      if (currentStep === 10) {
        this.rollbackPeriodAtIndex(2);
      }
      if (currentStep === 14) {
        this.rollbackPeriodAtIndex(3);
      }
      if (currentStep === 18) {
        this.rollbackPeriodAtIndex(4);
      }

      this.prevStep();
    },
    rollbackPeriodAtIndex: function rollbackPeriodAtIndex(index) {
      Ember.Logger.log('rollbackPeriodAtIndex ' + index);
      // ensure we have schedule at index
      if (this.get('schedule.periods.length') === index + 1) {
        var activePeriod = this.get('activePeriod');

        this.get('schedule.periods').removeObject(activePeriod);
        activePeriod.rollbackAttributes();
        //
        var nextPeriod = this.get('schedule.periods').get('lastObject');

        if (nextPeriod) {
          this.set('activePeriod', nextPeriod);
        } else {
          this.set('activePeriod', null);
        }
      }
    },
    rollbackSchedule: function rollbackSchedule() {
      var schedule = this.get('schedule'),
          periods = this.get('schedule.periods');
      if (periods) {
        for (var i = 0; i < periods.length; i++) {
          var period = periods[i];
          period.rollbackAttributes();
        }
      }
      if (schedule) {
        schedule.rollbackAttributes();
      }
      this.set('schedule', null);
      this.set('activePeriod', null);
      this.set('step', 0);
    }
  });
});