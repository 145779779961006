define('touch/app', ['exports', 'touch/resolver', 'ember-load-initializers', 'touch/config/environment', 'moment'], function (exports, _resolver, _emberLoadInitializers, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var env = _environment.default.environment;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  window.getURL = function (path) {
    return path.indexOf('http') === 0 ? path : _environment.default.rootApiURL + "/api/v1" + path;
  };

  _moment.default.updateLocale('en', {
    relativeTime: {
      future: "%s left",
      //s:  "today",
      m: "%d min",
      mm: "%d mins"
      // h:  "today",
      // hh: "today",
      // d:  "1 day",
      // dd: "%d days",
    }
  });

  Ember.$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (originalOptions.url.indexOf('https://query.yahooapis.com/v1/public') > -1) return;
    if (!jqXHR.crossDomain) {
      options.contentType = 'application/vnd.api+json';
      options.beforeSend = function (jqXHR) {
        jqXHR.setRequestHeader('Accept', 'application/vnd.api+json');
        jqXHR.setRequestHeader('Content-Type', 'application/vnd.api+json');
      };
      jqXHR.setRequestHeader('Authorization', localStorage.getItem("auth_token"));
      jqXHR.setRequestHeader('X-Gateway-Id', localStorage.getItem("gateway_id"));
      jqXHR.setRequestHeader('AppVersion', _environment.default.APP.version);
    }
  });

  Ember.Route.reopen({

    checkForRouteApp: function () {
      var cssClass = this.toCssClass();
      // you probably don't need the application class
      // to be added to the body
      if (cssClass !== 'route-application') {
        Ember.$('body').addClass(cssClass);
      } else {
        Ember.$('body').addClass('env-' + env);
      }
    }.on('activate'),

    deactivate: function deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass: function toCssClass() {
      return 'route-' + this.routeName.replace(/\./g, '-').dasherize();
    }

  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});