define('touch/controllers/internal/settings/address', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    toaster: Ember.inject.service('smd-toaster'),
    gateway: Ember.computed.alias('model'),
    actions: {
      save: function save(gateway) {
        var _this = this;

        gateway.save().then(function () {
          _this.get('toaster').setMessage('Address details updated', { timeout: 2000 });
          _this.transitionToRoute('internal.settings');
        }, function (error) {
          _this.get('toaster').setMessage(error.errors[0].detail, { timeout: 2000 });
        });
      }
    }
  });
});