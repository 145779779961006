define('touch/models/signup', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    gateway: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 14,
      max: 14
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    emailConfirmation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'email',
      message: 'Emails do not match'
    })],
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 255,
      message: '8 characters or more'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=(.*[a-z]){1,})(?=(.*[\d]){2,})(?!.*\s).{8,}$/,
      message: '{description} must contain letters and at least 2 numbers'
    })],
    passwordConfirmation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    })],
    line1: (0, _emberCpValidations.validator)('presence', true),
    town: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    agreeTermsAt: _emberData.default.attr('date'),
    gateway: _emberData.default.attr('string'),
    gatewayValidationError: _emberData.default.attr('string'),
    signUpError: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    emailConfirmation: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    line1: _emberData.default.attr('string'),
    line2: _emberData.default.attr('string'),
    town: _emberData.default.attr('string'),
    county: _emberData.default.attr('string'),
    postcode: _emberData.default.attr('string')
  });
});