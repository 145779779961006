define('touch/components/boiler-state', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    // Services
    gatewayChannel: service(),
    currentGatewayState: service(),
    homeService: service(),
    // Attributes
    classNames: ['boiler-state'],
    classNameBindings: ['modeClassName'],
    mode: 'menu', // menu, heating, water & combi
    heatingState: computed.alias('homeService.heatingState'), // off, on, auto
    waterState: computed.alias('homeService.waterState'), // off, on, auto
    feels: computed.alias('gatewayData.feels'), // cool, warm or hot
    // Computed
    /**
     * The mode class drives the view displayed.
     * Combi boilers always show a version of the
     * heating state
     */
    modeClassName: computed('homeService.mode', 'homeService.isCombi', function () {
      if (this.get('homeService.isCombi')) {
        return 'boiler-state--combi';
      }
      return 'boiler-state--' + this.get('homeService.mode');
    }),
    toggleClassName: computed('feels', function () {
      return 'toggle__item--' + this.get('feels');
    }),
    // Actions
    actions: {
      goToMenu: function goToMenu() {
        this.set('homeService.mode', 'menu');
        this.set('gatewayChannel.dataUpdatesPausedUntil', null);
        this.sendAction('action', 'internal.home');
      },
      goToHeating: function goToHeating() {
        this.set('homeService.mode', 'heating');
        this.set('gatewayChannel.dataUpdatesPausedUntil', (0, _moment.default)().add(1, 'day'));
        this.sendAction('action', 'internal.home.heating');
      },
      goToWater: function goToWater() {
        this.set('homeService.mode', 'water');
        this.set('gatewayChannel.dataUpdatesPausedUntil', (0, _moment.default)().add(1, 'day'));
        this.sendAction('action', 'internal.home.water');
      },
      setWaterState: function setWaterState(state) {
        //this.set('homeService.mode', 'water');
        this.set('homeService.waterState', state);
      },
      setHeatingState: function setHeatingState(state) {
        //this.set('homeService.mode', 'heating');
        this.set('homeService.heatingState', state);
        this.set('homeService.heatingOverrideType', 'manual');
        this.set('currentGatewayState.centralHeatingMode', 'manual');
        if (state === 'auto') {
          this.get('gatewayChannel').sendHeatingState();
          this.sendAction('closeAction', 'internal.home.combi');
        } else {
          this.sendAction('openAction', 'internal.home.combi');
        }
        // to be replace - action fired on override exit
        //this.get('gatewayData').operatingState(state);
      }
    }

  });
});