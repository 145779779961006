define('touch/mirage/config', ['exports', 'ember-cli-mirage', 'touch/config/environment'], function (exports, _emberCliMirage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    this.urlPrefix = _environment.default['rootApiURL']; // make this `http://localhost:8080`, for example, if your API is on a different server
    this.namespace = 'api/v1'; // make this `api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing


    /*
     * Functions
     */
    function errorResponse(errors) {
      return new Response(422, {
        'Content-Type': 'application/vnd.api+json'
      }, {
        errors: errors
      });
    }

    function successResponse(status, data) {
      return new Response(status, {
        'Content-Type': 'application/vnd.api+json'
      }, data);
    }

    /*
     * Authentication
     */
    this.get('/sessions', function (db) {
      var userId = window.localStorage.getItem('sessionUserId');

      if (userId) {
        var user = db.users.find(userId);

        if (user) {
          return user;
        }
      }
      var errors = [];
      return errorResponse(errors);
    });

    this.post('/sessions', function (db, request) {

      var body = JSON.parse(request.requestBody),
          result = db.users.where({
        username: body.data.attributes.username
      }),
          user = void 0,
          errors = [];

      if (body.data.attributes.email !== 'alreadyused@testing.co.uk') {
        errors.push({
          status: "422",
          source: {
            pointer: "/data/attributes/email"
          },
          title: "Invalid Attribute",
          detail: "Email address is already in use."
        });
      }
      if (body.data.attributes.password === 'wrong123') {
        errors.push({
          status: "422",
          source: {
            pointer: "/data/attributes/password"
          },
          title: "Invalid Attribute",
          detail: "Password is incorrect."
        });
      }

      if (result.length) {
        user = result.models[0];
        user.token = user.id;
        window.localStorage.setItem('sessionUserId', user.id);

        return user;
      } else {
        errors.push({
          status: "422",
          source: {
            pointer: "/data/attributes/username"
          },
          title: "Invalid Attribute",
          detail: "Username is incorrect."
        });
      }

      return errorResponse(errors);
    });

    this.delete('/sessions', function () {
      window.localStorage.removeItem('sessionUserId');
      return successResponse(204);
    });

    this.post('push-tokens');

    this.post('/users');

    this.post('/validate-gateway', function (schema, request) {
      var params = JSON.parse(request.requestBody);

      if (params.data.attributes.serial !== '1111-2222-3333') {
        return new _emberCliMirage.default.Response(422);
      } else {
        return new _emberCliMirage.default.Response(204);
      }
    });

    this.post('/check-email', function (schema, request) {
      var params = JSON.parse(request.requestBody);

      if (params.data.attributes.email === 'alreadyused@testing.co.uk') {
        return new _emberCliMirage.default.Response(422);
      } else {
        return new _emberCliMirage.default.Response(200);
      }
    });

    this.get('/devices');

    this.get('/gateways/:id');

    this.get('/holidays');
    this.post('/holidays');
    this.get('/holidays/:id');
    this.put('/holidays/:id');
    this.patch('/holidays/:id');
    this.del('/holidays/:id');

    this.get('/schedules');
    this.post('/schedules');
    this.get('/schedules/:id');
    this.put('/schedules/:id');
    this.patch('/schedules/:id');
    this.del('/schedules/:id');

    this.get('/periods');
    this.post('/periods');
    this.get('/periods/:id');
    this.put('/periods/:id');
    this.patch('/periods/:id');
    this.del('/periods/:id');

    this.get('/search-postcode', function (_ref, request) {
      var addresses = _ref.addresses;


      var postcode = request.queryParams.postcode;

      if (postcode.length > 5) {
        return addresses.all();
      }

      return [];
    });

    // Set up environmental
    if (_environment.default['ember-cli-mirage'].passthroughs) {
      _environment.default['ember-cli-mirage'].passthroughs.forEach(function (passthrough) {
        _this.passthrough(passthrough);
      });
    }
  };
});