define('touch/components/mdl-radio', ['exports', 'ember-material-lite/components/mdl-radio'], function (exports, _mdlRadio) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mdlRadio.default;
    }
  });
});