define('touch/controllers/internal/settings/boiler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    gatewayChannel: Ember.inject.service(),
    toaster: Ember.inject.service('smd-toaster'),
    actions: {
      updateOptimisationState: function updateOptimisationState(gatewayState) {
        gatewayState.toggleProperty('isInOptimisationMode');
        var state = gatewayState.getStateForSocket();
        Ember.Logger.log('state = ', state.optimisation_state);
        this.get('gatewayChannel').setOptimisationState(state);
        this.get('toaster').setMessage('Optimisation state updated', {
          timeout: 5000
        });
      }
    }
  });
});