define('touch/services/error-channel', ['exports', 'phoenix/services/phoenix-socket', 'touch/config/environment', 'ember-cordova-events/utils/subscribe'], function (exports, _phoenixSocket, _environment, _subscribe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _phoenixSocket.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    toaster: Ember.inject.service('smd-toaster'),

    boilerFaults: [],

    deviceResumed: (0, _subscribe.default)('cordovaEvents.resume', function () {
      this.connect();
    }),

    devicePaused: (0, _subscribe.default)('cordovaEvents.pause', function () {
      this.leave();
    }),

    connect: function connect() {
      var _this = this;

      Ember.Logger.log('=== Connect to websocket');
      if (_environment.default['ember-cli-mirage'].enabled) {
        Ember.Logger.log('=== Mirage Active - Do not connect to websockets');
        return;
      }

      var userJwt = localStorage.getItem("auth_token");

      if (Ember.isEmpty(userJwt)) return;

      if (this.get('channel') === undefined) {
        var protocol = _environment.default.rootApiURL.split("://")[0];
        var websocketProtocol = protocol == "https" ? "wss" : "ws";
        var url = _environment.default.rootApiURL.replace(protocol, "");

        this._super(websocketProtocol + url + "/error_socket", {
          params: {
            guardian_token: userJwt
          }
        });

        var gatewayId = localStorage.getItem("gateway_id");
        var channel = this.joinChannel("error:" + gatewayId);

        this.set('channel', channel);

        channel.push("retrieve_boiler_faults", {}).receive("ok", function (payload) {
          _this.get('store').pushPayload(payload);
          _this.setBoilerFaults();
        });

        channel.on("errors_cleared", function (payload) {
          _this.get('store').unloadAll('error');
          _this.set('boilerFaults', []);
        });

        channel.on("error_occurred", function (payload) {
          _this.get('store').pushPayload(payload);
          var lastError = _this.get('store').peekAll('error').get('lastObject');
          _this.get('toaster').setMessage(lastError.get('message'), {
            timeout: 5000
          });
          _this.setBoilerFaults();
        });
      }
    },


    leave: function leave() {
      if (!Ember.isEmpty(this.get('channel'))) {
        this.get('channel').leave();
        this.set('channel', undefined);
      }
      if (!Ember.isEmpty(this.get('socket'))) {
        this.get('socket').disconnect();
      }
    },

    setBoilerFaults: function setBoilerFaults() {
      var faults = this.get('store').peekAll('error').filterBy('error_type', 'Boiler fault');
      this.set('boilerFaults', faults);
    }

  });
});