define('touch/components/system-override', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      alias = _Ember$computed.alias,
      service = Ember.inject.service,
      observer = Ember.observer;
  exports.default = Component.extend({
    currentGatewayState: service(),
    homeService: service(),
    // Attributes
    classNames: 'override',
    classNameBindings: ['stateModifierClass'],
    showModal: null,
    state: 'heating', // Heating or Water
    // Computed
    isCombi: alias('homeService.isCombi'),
    isWater: equal('state', 'water'),
    isHeating: equal('state', 'heating'),
    stateModifierClass: computed('state', 'homeService.heatingState', 'homeService.waterState', function () {
      if (this.get('state') === 'water') {
        return 'override--' + this.get('homeService.waterState');
      }
      return 'override--' + this.get('homeService.heatingState');
    }),
    autoOverrideText: computed('currentGatewayState.centralHeatingNextPeriodStart', 'homeService.heatingState', function () {
      var time = _moment.default.utc(this.get('currentGatewayState.centralHeatingNextPeriodStart')).format('HH:mm');
      if (this.get('homeService.heatingState') === 'on') {
        return 'Heating will continue until the next period at ' + time;
      } else {
        return 'Heating will stop until the next period at ' + time;
      }
    }),
    manualOverrideText: computed('homeService.heatingState', function () {
      if (this.get('homeService.heatingState') === 'on') {
        return 'Heating will continue until switched off';
      } else {
        return 'Heating will stop until switched on';
      }
    }),
    autoStatusText: computed('currentGatewayState.centralHeatingPeriodStart', 'currentGatewayState.centralHeatingPeriodEnd', 'homeService.heatingState', function () {
      // let time = moment.utc(this.get('currentGatewayState.centralHeatingNextPeriodStart')).format('HH:mm');
      // if (this.get('homeService.heatingState') === "on") {
      //   return 'Heating will continue until the next period at ' + time;
      // } else {
      //   return 'Heating will stop until the next period at ' + time;
      // }
      var periodStartTime = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodStart')).format('HHmm');
      var periodEndTime = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodEnd')).format('HHmm');
      var currentTime = (0, _moment.default)().format('HHmm');

      if (parseInt(periodStartTime) > parseInt(currentTime)) {
        var time = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodStart')).format('HH:mm');
        return 'Your heating is scheduled to come on later at ' + time;
      }

      if (parseInt(periodStartTime) < parseInt(currentTime) && parseInt(periodEndTime) > parseInt(currentTime)) {
        var _time = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodEnd')).format('HH:mm');
        return 'Your heating is scheduled to turn off later at ' + _time;
      }

      if (parseInt(periodStartTime) < parseInt(currentTime) && parseInt(periodEndTime) < parseInt(currentTime)) {
        var _time2 = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodStart')).format('HH:mm');
        return 'Your heating is scheduled to come on tomorrow at ' + _time2;
      }
    }),
    overrideType: computed('currentGatewayState.centralHeatingMode', function () {
      return this.get('currentGatewayState.centralHeatingMode');
    }), // Manual, Timer or Auto
    overrideTime: computed('currentGatewayState.centralHeatingTimerMinutes', function () {
      return this.get('currentGatewayState.centralHeatingTimerMinutes');
    }),
    // Observers
    typeChanged: Ember.observer('overrideType', function () {
      if (this.get('isWater')) {
        this.set('homeService.waterOverrideType', this.get('overrideType'));
      } else {
        this.set('homeService.heatingOverrideType', this.get('overrideType'));
      }
    }),
    timeChanged: Ember.observer('overrideTime', function () {
      Ember.Logger.log('+++ changed +++' + this.get('overrideTime'));
      if (this.get('isWater')) {
        this.set('homeService.waterOverrideTime', this.get('overrideTime'));
      } else {
        Ember.Logger.log('override the system' + this.get('overrideTime'));
        this.set('homeService.heatingOverrideTime', this.get('overrideTime'));
      }
    }),
    // Actions
    actions: {
      goToMenu: function goToMenu() {
        //this.set('homeService.mode', 'menu');
        this.set('showModal', false);
        this.sendAction('action', 'internal.home.index');
      }
    }
  });
});