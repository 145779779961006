define("touch/routes/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend({
    toaster: service('smd-toaster'),
    gatewayChannel: service(),
    errorChannel: service(),
    fullScreenError: service(),
    actions: {
      error: function error(errors, transition) {
        Ember.Logger.error(errors);
        if (errors.errors !== undefined) {
          if (errors.errors[0] === "Invalid login credentials") {
            this.logout();
          }
          if (errors.errors[0].status === "0") {
            this.set('fullScreenError.errorMessage', 'API is offline');
            this.set('fullScreenError.failedTransition', transition);
          }
          if (errors.errors[0].status === "418") {
            this.transitionTo('update-app');
          }
        }
      },
      accessDenied: function accessDenied(transition) {
        localStorage.clear();
        return this.transitionTo("login");
      },
      logout: function logout() {
        this.logout();
      },
      didTransition: function didTransition(transition) {
        this.set('fullScreenError.errorMessage', null);
      },
      retry: function retry() {
        var lastTransition = this.get('fullScreenError.failedTransition');
        if (lastTransition) {
          lastTransition.retry();
        }
      }
    },

    //Services used for push notifications
    appState: Ember.inject.service(),
    cordovaEvents: Ember.inject.service('ember-cordova/events'),

    activate: function activate() {
      //If the app is running in cordova (on a mobile device), use its actual device token.
      // Otherwise, use a dummy device token
      if (window.cordova !== undefined) {
        Ember.Logger.log("Cordova detected - using device's token");
        this.get('cordovaEvents').on('deviceready', this, '_deviceReady');
      } else {
        Ember.Logger.log("Cordova not found - using dummy device token");
        //this.saveDeviceToken("devicetoken001"); //Dummy device token
      }
    },


    //Deactivate function should theoretically never be called in this route,
    // but we'll have it in anyway.
    deactivate: function deactivate() {
      if (window.cordova !== undefined) {
        this.get('cordovaEvents').off('deviceready', this, '_deviceReady');
      }
    },


    //If cordova is present, the _deviceReady will initiaize all
    // push notification elements
    _deviceReady: function _deviceReady() {
      var _this = this;

      Ember.Logger.log("Device ready!");
      // Android only status bar colour override
      StatusBar.backgroundColorByHexString("#3e3e3e");

      window.open = cordova.InAppBrowser.open;
      var push = PushNotification.init({
        "ios": {
          "sound": "true",
          "alert": "true",
          "badge": "true",
          "clearBadge": "true"
        },
        "android": {
          //Android config goes here
        }
      });

      // Force external links to open in the system
      this.sortExternalLinks();

      //Get device info when app launches in cordova
      push.on('registration', function (data) {
        Ember.Logger.log("PushNotification: registration");
        _this.set('appState.devicePlatform', window.device.platform.toLowerCase()); //window.device is only available in cdv
        _this.saveDeviceToken(data.registrationId);
      });

      //Foreground notification listener
      push.on('notification', function (data) {
        Ember.Logger.log("PushNotification: notification - begin");
        //navigator.notification.alert(
        //  data.message, // message
        //  function(){Ember.Logger.log("Foreground notification callback")}, // callback
        //  'Ideal Touch', // title
        //  'Ok' // buttonName
        //);
        push.finish(function () {
          Ember.Logger.log("PushNotification: notification - end (success)");
        }, function () {
          Ember.Logger.log("PushNotification: notification - end (error)");
        }, data.additionalData.notId);
      });

      push.on('error', function (e) {
        Ember.Logger.log("PushNotification: error");
        console.error(e.message);
      });
    },


    sortExternalLinks: function sortExternalLinks() {
      $(document).on('click', 'a[href^=http], a[href^=https]', function (e) {
        e.preventDefault();
        var $this = $(this);
        var target = $this.data('inAppBrowser') || '_blank';

        cordova.InAppBrowser.open($this.attr('href'), '_system', 'location=no');
      });
    },

    logout: function logout() {
      var _this2 = this;

      Ember.Logger.log('logout');
      return this.get('session').close().then(function () {
        _this2.get('gatewayChannel').leave();
        _this2.get('errorChannel').leave();
        _this2.get('store').unloadAll();
        _this2.get('toaster').setMessage("Goodbye...", {
          timeout: 3000
        });
        Ember.Logger.log('done');
        _this2.transitionTo('login');
      });
    },

    saveDeviceToken: function saveDeviceToken(deviceToken) {
      Ember.Logger.log("saveDeviceToken: " + deviceToken);
      this.set('appState.deviceToken', deviceToken);
    }

  });
});