define('touch/routes/internal/new-schedule/heating', ['exports', 'touch/mixins/rollbackable-route'], function (exports, _rollbackableRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, {
    // Services
    header: Ember.inject.service('header-service'),
    scheduler: Ember.inject.service('heating-scheduler'),
    // Methods
    activate: function activate() {
      this.set('header.backAction', 'back');
    },
    model: function model() {
      return this.store.createRecord('schedule');
    }
  });
});