define('touch/services/home-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      computed = Ember.computed;
  exports.default = Service.extend({
    isCombi: true,
    mode: 'menu', // menu, heating, water
    heatingState: 'off', // on, off, auto
    waterState: 'off', // on, off, auto
    heatingOverrideType: 'manual', // Manual, Timer or Auto
    waterOverrideType: 'manual', // Manual, Timer or Auto
    heatingOverrideTime: 0,
    waterOverrideTime: 0,
    // Computed
    overrideHeating: computed('heatingState', 'heatingOverrideType', 'heatingOverrideTime', function () {
      return {
        mode: 'heating',
        state: this.get('heatingState'),
        type: this.get('heatingOverrideType'),
        time: this.get('heatingOverrideTime')
      };
    }),
    overrideWater: computed('waterState', 'waterOverrideType', 'waterOverrideTime', function () {
      return {
        mode: 'water',
        state: this.get('waterState'),
        type: this.get('waterOverrideType'),
        time: this.get('waterOverrideTime')
      };
    })
  });
});