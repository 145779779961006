define('touch/services/header-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    heading: null,
    backRoute: null,
    backAction: null,
    actionRoute: null,
    actionAction: null,
    backText: 'back',
    actionText: 'action'
  });
});