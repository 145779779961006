define('touch/models/period', ['exports', 'ember-data', 'ember-cp-validations', 'touch/utils/time-helpers'], function (exports, _emberData, _emberCpValidations, _timeHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    start: [(0, _emberCpValidations.validator)('presence', true)],
    end: [(0, _emberCpValidations.validator)('presence', true)],
    temp: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend(Validations, {
    // Attributes
    startTime: attr('string'),
    endTime: attr('string'),
    temperature: attr('number', {
      defaultValue: 20
    }),
    // Relationships
    schedule: belongsTo('schedule'),
    // Computed
    temp: Ember.computed.alias('temperature'),
    start: Ember.computed('startTime', function () {
      return (0, _timeHelpers.timeToDecimal)(Ember.get(this, 'startTime'));
    }),
    end: Ember.computed('endTime', function () {
      return (0, _timeHelpers.timeToDecimal)(Ember.get(this, 'endTime'));
    })
  });
});