define('touch/routes/internal/schedules/index', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend(_backButtonable.default, {
    gatewayChannel: service(),
    beforeModel: function beforeModel() {
      this.get('gatewayChannel').connect();
      this.transitionTo('internal.schedules.heating');
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.home.index');
    }
  });
});