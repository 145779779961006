define('touch/routes/internal/settings/address', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _rollbackableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    model: function model() {
      return this.get('session.currentUser.gateway');
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.settings');
    }
  });
});