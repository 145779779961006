define('touch/routes/internal/holidays/new', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable', 'moment'], function (exports, _rollbackableRoute, _backButtonable, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    // Methods
    model: function model() /*params*/{
      return this.store.createRecord('holiday', {
        name: 'My Holiday',
        startDate: (0, _moment.default)().format('YYYY-MM-DD'),
        endDate: (0, _moment.default)().add(7, 'days').format('YYYY-MM-DD'),
        startTime: '09:00',
        endTime: '09:00'
      });
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.holidays.index');
    }
  });
});