define('touch/services/gateway-channel', ['exports', 'phoenix/services/phoenix-socket', 'touch/config/environment', 'ember-cordova-events/utils/subscribe', 'moment'], function (exports, _phoenixSocket, _environment, _subscribe, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _phoenixSocket.default.extend({
    store: Ember.inject.service(),
    currentGatewayState: Ember.inject.service(),
    fullScreenError: Ember.inject.service(),
    cordovaEvents: Ember.inject.service('ember-cordova/events'),

    // Properties
    centralHeatingState: null, // on, off, auto
    centralHeatingMode: null, // manual, timer, next
    centralHeatingBoostActivated: null,
    centralHeatingTimerMinutes: null, // e.g. 01:30 == 90mins
    centralHeatingTimerEnds: null, // e.g. 18:30
    centralHeatingNextPeriodStart: null, // e.g. 22:30
    centralHeatingNextPeriodEnd: null,
    centralHeatingNextPeriodTargetTemperature: null,
    centralHeatingCurrentPeriodStart: null, // e.g. 22:30
    centralHeatingCurrentPeriodEnd: null,
    centralHeatingCurrentTemperature: null, // e.g. 19.5
    centralHeatingTargetTemperature: null, // e.g. 22.5
    centralHeatingOverrideMode: null, // onuntil, offuntil, disabled
    centralHeatingOverrideTimeState: null, // e.g. 2017-05-05T22:30:00
    centralHeatingClosestScheduledTemperature: null,

    optimisationState: null, // on, off

    flameSymbol: null, // on, off
    gatewayState: null,

    heatingState: null,
    waterState: null,
    currentTemp: null,
    targetTemp: null,

    holidayId: null,
    holidayEndsAt: null,

    outsideTemperature: null,
    weatherType: null,

    wsTimeoutId: null,
    dataUpdatesPausedUntil: null,

    overkizState: 'alive',

    showErrorTimer: null,

    deviceResumed: (0, _subscribe.default)('cordovaEvents.resume', function () {
      this.connect();
    }),

    devicePaused: (0, _subscribe.default)('cordovaEvents.pause', function () {
      this.leave();
    }),

    init: function init() {
      var _this = this;

      this.on('error', function () {
        var timer = Ember.run.later(function () {
          _this.set('fullScreenError.errorMessage', 'Platform is offline');
        }, 2000);

        _this.set('showErrorTimer', timer);
      });

      this.on('join', function (status, topic, channel) {
        var message = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

        switch (status) {
          case 'ok':
            Ember.run.cancel(_this.get('showErrorTimer'));
            _this.set('fullScreenError.errorMessage', '');
            _this.retrieveLatestGatewayData(channel);
            break;
          case 'error':
            Ember.run.cancel(_this.get('showErrorTimer'));
            break;
          default:
            Ember.run.cancel(_this.get('showErrorTimer'));
            _this.set('fullScreenError.errorMessage', 'Platform is offline');
            throw 'Joining Phoenix Channel failed!';
        }
      });
    },


    // Computed
    connect: function connect() {
      var _this2 = this;

      Ember.Logger.log('=== Connect to websocket');
      if (_environment.default['ember-cli-mirage'].enabled) {
        Ember.Logger.log('=== Mirage Active - Do not connect to websockets');
        return;
      }

      var userJwt = localStorage.getItem("auth_token");

      if (Ember.isEmpty(userJwt)) return;

      if (this.get('channel') === undefined) {
        var protocol = _environment.default.rootApiURL.split("://")[0];
        var websocketProtocol = protocol == "https" ? "wss" : "ws";
        var url = _environment.default.rootApiURL.replace(protocol, "");

        this._super(websocketProtocol + url + '/data_socket', {
          params: {
            guardian_token: userJwt
          }
        });

        var lobby = this.joinChannel('gateway:lobby');
        var channel = this.connectToGatewaySpecificChannel();

        this.set('lobby', lobby);

        channel.on('data_updated', function (payload) {
          _this2.set('currentGatewayState.awaitingTargetTempUpdate', false);
          var dataUpdatesPausedUntil = _this2.get('dataUpdatesPausedUntil');
          if ((0, _moment.default)() >= dataUpdatesPausedUntil) {
            _this2._setAttrs(payload);
          }
        });

        channel.on('schedules_updated', function (payload) {
          Ember.run.once(function () {
            //this.get('store').unloadAll('period');
            //this.get('store').unloadAll('schedule');
            Ember.run.later(function () {
              _this2.get('store').query('schedule', {});
            }, 1000);
            _this2.retrieveLatestGatewayData(channel);
          });
        });

        lobby.on('overkiz_state', function (payload) {
          var state = payload['data']['attributes']['service-state'];

          if (state !== 'alive') {
            _this2.set('fullScreenError.errorMessage', 'Platform is offline');
          } else {
            _this2.set('fullScreenError.errorMessage', '');
          }
        });
      }
    },


    leave: function leave() {
      this._clearAllSocketData();
      if (!Ember.isEmpty(this.get('channel'))) {
        this.get('channel').leave();
        this.set('channel', undefined);
      }
      if (!Ember.isEmpty(this.get('lobby'))) {
        this.get('lobby').leave();
        this.set('lobby', undefined);
      }
      if (!Ember.isEmpty(this.get('socket'))) {
        this.get('socket').disconnect();
      }
    },

    setOperatingState: function setOperatingState(state) {
      var _this3 = this;

      // before sending state - pause web socket data
      this.set('dataUpdatesPausedUntil', (0, _moment.default)().add(10, 'seconds'));
      this.get('channel').push('operating_state', state).receive('ok', function (resp) {
        _this3._setAttrs(resp);
        // Noop.. wait for the gateway data update service to ping us later.
      }).receive('error', function (payload) {
        // Noop.. wait for the gateway data update service to ping us later.
      });
    },

    setTargetTemperature: function setTargetTemperature(state /*, callback*/) {
      if (this.get('currentGatewayState.centraHeatingTargetTemperature') !== 'off') {
        this.set('currentGatewayState.centralHeatingTargetTemperature', state);
        Ember.run.debounce(this, this.updateTargetTemp, 1500);
      }
    },

    setOptimisationState: function setOptimisationState(state) {
      var _this4 = this;

      this.set('currentGatewayState.optimisationState', state);
      this.get('channel').push('optimisation_state', state).receive('ok', function (resp) {
        _this4._setAttrs(resp);
        // Noop.. wait for the gateway data update service to ping us later.
      }).receive('error', function (payload) {
        // Noop.. wait for the gateway data update service to ping us later.
      });
    },

    updateTargetTemp: function updateTargetTemp() {
      var _this5 = this;

      var state = this.get('currentGatewayState').getStateForSocket();
      this.set('dataUpdatesPausedUntil', (0, _moment.default)().add(30, 'seconds'));
      this.get('channel').push('target_temperature', state).receive('ok', function (resp) {
        _this5._setAttrs(resp);
      }).receive('error', function () /*payload*/{
        // Noop
      });
    },

    _clearAllSocketData: function _clearAllSocketData() {
      var _this6 = this;

      var centralHeatingState = null,
          centralHeatingMode = null,
          centralHeatingDisplayState = null,
          centralHeatingDisplayMode = null,
          centralHeatingBoostActivated = null,
          centralHeatingTimerMinutes = null,
          centralHeatingTimerEnds = null,
          centralHeatingNextPeriodStart = null,
          centralHeatingNextPeriodEnd = null,
          centralHeatingNextPeriodTargetTemperature = null,
          centralHeatingCurrentPeriodStart = null,
          centralHeatingCurrentPeriodEnd = null,
          centralHeatingCurrentTemperature = null,
          centralHeatingTargetTemperature = null,
          centralHeatingClosestScheduledTemperature = null,
          optimisationState = null,
          outsideTemperature = null,
          weatherType = null,
          flameSymbol = null,
          gatewayState = null,
          centralHeatingOverrideTimerEnds = null,
          centralHeatingCountdownDisplayEnds = null,
          holidayId = null,
          holidayEndsAt = null;

      Ember.run.once(function () {
        _this6.set('currentGatewayState.centralHeatingState', centralHeatingState);
        _this6.set('currentGatewayState.centralHeatingMode', centralHeatingMode);
        _this6.set('currentGatewayState.centralHeatingDisplayState', centralHeatingDisplayState);
        _this6.set('currentGatewayState.centralHeatingDisplayMode', centralHeatingDisplayMode);
        _this6.set('currentGatewayState.centralHeatingBoostActivated', centralHeatingBoostActivated);
        _this6.set('currentGatewayState.centralHeatingTimerMinutes', centralHeatingTimerMinutes);
        _this6.set('currentGatewayState.centralHeatingTimerEnds', centralHeatingTimerEnds);
        _this6.set('currentGatewayState.centralHeatingNextPeriodStart', centralHeatingNextPeriodStart);
        _this6.set('currentGatewayState.centralHeatingNextPeriodEnd', centralHeatingNextPeriodEnd);
        _this6.set('currentGatewayState.centralHeatingNextPeriodTargetTemperature', centralHeatingNextPeriodTargetTemperature);
        _this6.set('currentGatewayState.centralHeatingCurrentPeriodStart', centralHeatingCurrentPeriodStart);
        _this6.set('currentGatewayState.centralHeatingCurrentPeriodEnd', centralHeatingCurrentPeriodEnd);
        _this6.set('currentGatewayState.centralHeatingCurrentTemperature', centralHeatingCurrentTemperature);
        _this6.set('currentGatewayState.centralHeatingTargetTemperature', centralHeatingTargetTemperature);
        _this6.set('currentGatewayState.centralHeatingOverrideTimerEnds', centralHeatingOverrideTimerEnds);
        _this6.set('currentGatewayState.centralHeatingClosestScheduledTemperature', centralHeatingClosestScheduledTemperature);
        _this6.set('currentGatewayState.optimisationState', optimisationState);
        _this6.set('currentGatewayState.flameSymbol', flameSymbol);
        _this6.set('currentGatewayState.gatewayState', gatewayState);
        _this6.set('currentGatewayState.centralHeatingCountdownDisplayEnds', centralHeatingCountdownDisplayEnds);
        _this6.set('currentGatewayState.holidayId', holidayId);
        _this6.set('currentGatewayState.holidayEndsAt', holidayEndsAt);
        _this6.set('currentGatewayState.outsideTemperature', outsideTemperature);
        _this6.set('currentGatewayState.weatherType', weatherType);
      });
    },

    _setAttrs: function _setAttrs(payload) {
      var _this7 = this;

      var centralHeatingState = payload.data.attributes['central-heating-state'];
      var centralHeatingMode = payload.data.attributes['central-heating-mode'];
      var centralHeatingDisplayState = payload.data.attributes['central-heating-display-state'];
      var centralHeatingDisplayMode = payload.data.attributes['central-heating-display-mode'];
      var centralHeatingBoostActivated = payload.data.attributes['central-heating-boost-activated'];
      var centralHeatingTimerMinutes = payload.data.attributes['central-heating-timer-minutes'];
      var centralHeatingTimerEnds = payload.data.attributes['central-heating-timer-ends'];
      var centralHeatingNextPeriodStart = payload.data.attributes['central-heating-next-period-start'];
      var centralHeatingNextPeriodEnd = payload.data.attributes['central-heating-next-period-end'];
      var centralHeatingNextPeriodTargetTemperature = payload.data.attributes['central-heating-next-period-target-temperature'];
      var centralHeatingCurrentPeriodStart = payload.data.attributes['central-heating-current-period-start'];
      var centralHeatingCurrentPeriodEnd = payload.data.attributes['central-heating-current-period-end'];
      var centralHeatingCurrentTemperature = payload.data.attributes['central-heating-current-temperature'];
      var centralHeatingTargetTemperature = payload.data.attributes['central-heating-target-temperature'];
      var centralHeatingClosestScheduledTemperature = payload.data.attributes['central-heating-closest-scheduled-temperature'];
      var optimisationState = payload.data.attributes['optimisation-state'];
      var flameSymbol = payload.data.attributes['flame-symbol'];
      var gatewayState = payload.data.attributes['gateway-state'];
      var centralHeatingOverrideTimerEnds = payload.data.attributes['central-heating-override-time-state']; // central-heating-override-timer-ends
      var centralHeatingCountdownDisplayEnds = payload.data.attributes['central-heating-countdown-display-ends'];
      var holidayId = payload.data.attributes['holiday-id'];
      var holidayEndsAt = payload.data.attributes['holiday-ends-at'];
      var outsideTemperature = payload.data.attributes['current-outside-temperature'];
      var weatherType = payload.data.attributes['current-outside-weather-type'];

      Ember.run.once(function () {
        _this7.set('currentGatewayState.centralHeatingState', centralHeatingState);
        _this7.set('currentGatewayState.centralHeatingMode', centralHeatingMode);
        _this7.set('currentGatewayState.centralHeatingDisplayState', centralHeatingDisplayState);
        _this7.set('currentGatewayState.centralHeatingDisplayMode', centralHeatingDisplayMode);
        _this7.set('currentGatewayState.centralHeatingBoostActivated', centralHeatingBoostActivated);
        _this7.set('currentGatewayState.centralHeatingTimerMinutes', centralHeatingTimerMinutes);
        _this7.set('currentGatewayState.centralHeatingTimerEnds', centralHeatingTimerEnds);
        _this7.set('currentGatewayState.centralHeatingNextPeriodStart', centralHeatingNextPeriodStart);
        _this7.set('currentGatewayState.centralHeatingNextPeriodEnd', centralHeatingNextPeriodEnd);
        _this7.set('currentGatewayState.centralHeatingNextPeriodTargetTemperature', centralHeatingNextPeriodTargetTemperature);
        _this7.set('currentGatewayState.centralHeatingCurrentPeriodStart', centralHeatingCurrentPeriodStart);
        _this7.set('currentGatewayState.centralHeatingCurrentPeriodEnd', centralHeatingCurrentPeriodEnd);
        _this7.set('currentGatewayState.centralHeatingCurrentTemperature', centralHeatingCurrentTemperature);
        _this7.set('currentGatewayState.centralHeatingTargetTemperature', centralHeatingTargetTemperature);
        _this7.set('currentGatewayState.centralHeatingOverrideTimerEnds', centralHeatingOverrideTimerEnds);
        _this7.set('currentGatewayState.centralHeatingClosestScheduledTemperature', centralHeatingClosestScheduledTemperature);
        _this7.set('currentGatewayState.optimisationState', optimisationState);
        _this7.set('currentGatewayState.flameSymbol', flameSymbol);
        _this7.set('currentGatewayState.gatewayState', gatewayState);
        _this7.set('currentGatewayState.centralHeatingCountdownDisplayEnds', centralHeatingCountdownDisplayEnds);
        _this7.set('currentGatewayState.holidayId', holidayId);
        _this7.set('currentGatewayState.holidayEndsAt', holidayEndsAt);
        _this7.set('currentGatewayState.outsideTemperature', outsideTemperature);
        _this7.set('currentGatewayState.weatherType', weatherType);
      });
    },

    connectToGatewaySpecificChannel: function connectToGatewaySpecificChannel() {
      var gatewayId = localStorage.getItem('gateway_id');
      var channel = this.joinChannel('gateway:' + gatewayId);
      channel = this.joinChannel('gateway:' + gatewayId);
      this.set('channel', channel);
      this.retrieveLatestGatewayData(channel);
      return channel;
    },

    gatewayNotConnected: function gatewayNotConnected() {
      return this.get('channel') === undefined || this.get('channel.state') === 'closed' || this.get('channel.state') === 'errored';
    },

    retrieveLatestGatewayData: function retrieveLatestGatewayData(channel) {
      var _this8 = this;

      if (channel == undefined) return;

      channel.push('retrieve_data', {}).receive('ok', function (payload) {
        _this8._setAttrs(payload);
      });
    },

    sendHeatingState: function sendHeatingState() {
      this.set('currentGatewayState.awaitingTargetTempUpdate', true);
      Ember.run.debounce(this, this.sendHeatingCommands, 1500);
    },

    sendHeatingCommands: function sendHeatingCommands() {
      if (this.get('currentGatewayState.centralHeatingDisplayState') !== null) {
        // Update heating state

        this.get('currentGatewayState').syncStateFromHomeSocket();
        var state = this.get('currentGatewayState').getStateForSocket();
        var wantedHeatingState = state["central_heating_display_state"];
        var previousHeatingState = this.get('currentGatewayState.centralHeatingState');

        if (wantedHeatingState == previousHeatingState) {
          this.set('currentGatewayState.awaitingTargetTempUpdate', false);
        }
        this.setOperatingState(state);
      }
    }
  });
});