define('touch/components/smd-badge', ['exports', 'ember-sauce-material-design/components/smd-badge'], function (exports, _smdBadge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdBadge.default;
    }
  });
});