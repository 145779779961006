define('touch/routes/internal/home', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    // Services
    gatewayChannel: Ember.inject.service(),
    errorChannel: Ember.inject.service(),
    appState: Ember.inject.service(),
    currentGatewayState: Ember.inject.service(),
    // Observer
    /**
     * The user may be on the home screen when the gateway enters
     * a holiday.
     *
     * At this point afterModel has already been called and so will
     * not redirect the user. We will observer the gateways websocket
     * state instead and redirect if it changes to a holiday.
     **/
    observerCurrentHoliday: Ember.observer('currentGatewayState.holidayId', function () {
      // Get the current holiday id state
      var holidayId = Ember.get(this, 'currentGatewayState.holidayId');
      // Check it is not null
      if (!Ember.isEmpty(holidayId)) {
        // Load up the holiday route
        this.transitionTo('internal.on-holiday', holidayId);
      }
    }),
    // Methods
    beforeModel: function beforeModel() {
      this.checkNeedsNewPassword();
    },
    model: function model() {
      var gatewayId = Ember.get(this, 'session.currentUser.gateway.id');
      return this.store.findRecord('gateway', gatewayId, {
        reload: true,
        include: 'currentHoliday'
      });
    },

    /**
     * The home page model is the app gateway.
     *
     * After this is loaded we need to check in the gateways device
     * data to see if it's currently in a holiday mode. If it is on
     * holiday, we redirect the user to a read only on-holiday route
     **/
    afterModel: function afterModel(model) {
      // Check for a holiday id in the gateway data
      var holidayId = Ember.get(model, 'currentHoliday.id');
      // Check yo self
      if (!Ember.isEmpty(holidayId)) {
        // Load up the holiday route
        this.transitionTo('internal.on-holiday', holidayId);
      }
    },
    activate: function activate() {
      this.get('gatewayChannel').connect();
      this.get('errorChannel').connect();
      // Ember.Logger.log(this.get('gatewayChannel'));
      //Create push-token record if device token is available.
      // Race case if home route is the first thing when app is loaded,
      // in which case this code is run BEFORE cordova manages to register
      // a device token. This, however, shouldn't be a problem as the token
      // should already have been created immediately after logging in or
      // registering.
      if (this.get('appState.deviceToken')) {
        var postData = {
          format: 'json-api',
          data: {
            type: 'push-token',
            attributes: {
              token: this.get('appState.deviceToken'),
              platform: this.get('appState.devicePlatform')
            }
          }
        };

        Ember.$.post(window.getURL('/push-tokens'), JSON.stringify(postData));
      }
    },
    checkNeedsNewPassword: function checkNeedsNewPassword() {
      if (this.get('session.currentUser.requiresNewPassword')) {
        return this.transitionTo('internal.settings.password');
      }
    },
    backButtonHandler: function backButtonHandler() {
      navigator.app.exitApp();
    },

    // Actions
    actions: {
      goToRoute: function goToRoute(route) {
        this.transitionTo(route, {
          queryParams: {
            send_new_state: 'true'
          }
        });
      }
    }
  });
});