define('touch/models/boiler-fault', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      computed = _Ember.computed;
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),

    hasLink: computed('link', function () {
      return this.get('link') !== null;
    })
  });
});