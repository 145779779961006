define('touch/components/period-temp-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_TEMP = 30;
  var MIN_TEMP = 10;

  exports.default = Ember.Component.extend({
    classNames: ['list__item', 'list__item--narrow', 'list__item--no-border'],
    actions: {
      tempDown: function tempDown(model) {
        var temp = parseFloat(model.get('temp'));
        if (temp > MIN_TEMP) {
          model.set('temp', temp - 0.5);
        }
      },
      tempUp: function tempUp(model) {
        var temp = parseFloat(model.get('temp'));
        if (temp < MAX_TEMP) {
          model.set('temp', temp + 0.5);
        }
      }
    }
  });
});