define('touch/routes/signup/completed', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    backButtonHandler: function backButtonHandler() {
      return;
    },


    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      },
      goToHome: function goToHome() {
        this.transitionTo('internal.home');
      }
    }
  });
});