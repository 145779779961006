define('touch/routes/internal/settings/app', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend(_backButtonable.default, {
    toaster: service('smd-toaster'),
    homeService: service(),
    model: function model() {
      return Ember.Object.create({
        isCombi: this.get('homeService.isCombi')
      });
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.settings');
    },

    actions: {
      save: function save() {
        var model = this.modelFor(this.routeName);
        this.set('homeService.isCombi', model.get('isCombi'));
        this.get('toaster').setMessage('App settings updated', { timeout: 5000 });
      }
    }
  });
});