define('touch/controllers/internal/on-holiday', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    // Methods
    model: function model() {
      return this.modelFor('holiday');
    },

    // Actions
    actions: {
      cancelHoliday: function cancelHoliday(holiday) {
        var _this = this;

        var toast = Ember.get(this, 'toaster');

        holiday.deleteRecord();
        holiday.save().then(function () {
          toast.setMessage('Holiday canceled', {
            timeout: 5000
          });
          _this.transitionToRoute('internal.home');
        }, function () /*error*/{
          toast.setMessage('Server Error', {
            timeout: 5000
          });
        });
      }
    }
  });
});