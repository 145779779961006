define('touch/models/holiday', ['exports', 'ember-data', 'touch/mixins/validation/holiday', 'moment'], function (exports, _emberData, _holiday, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend(_holiday.default, {
    // Attributes
    name: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('string', {
      defaultValue: null
    }),
    endDate: _emberData.default.attr('string', {
      defaultValue: null
    }),
    startTime: _emberData.default.attr('string', {
      defaultValue: '00:00'
    }),
    endTime: _emberData.default.attr('string', {
      defaultValue: '00:00'
    }),
    endsAt: computed('endDate', 'endTime', function () {
      var format = this.get('endDate') + ' ' + this.get('endTime');

      return (0, _moment.default)(format, 'YYYY-MM-DD HH:mm');
    })
  });
});