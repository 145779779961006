define("touch/mirage/fixtures/periods", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    startTime: "07:30",
    endTime: "09:00",
    temperature: 19,
    scheduleId: 1
  }, {
    startTime: "15:30",
    endTime: "22.30",
    temperature: 22,
    scheduleId: 1
  }, {
    startTime: "07:30",
    endTime: "09:00",
    temperature: 19,
    scheduleId: 2
  }, {
    startTime: "15:30",
    endTime: "22.30",
    temperature: 22,
    scheduleId: 2
  }, {
    startTime: "07:30",
    endTime: "09:00",
    temperature: 19,
    scheduleId: 3
  }, {
    startTime: "15:30",
    endTime: "22.30",
    temperature: 22,
    scheduleId: 3
  }, {
    startTime: "07:30",
    endTime: "09:00",
    temperature: 21,
    scheduleId: 4
  }, {
    startTime: "15:30",
    endTime: "22.30",
    temperature: 22,
    scheduleId: 4
  }, {
    startTime: "07:30",
    endTime: "09:00",
    temperature: 21,
    scheduleId: 5
  }, {
    startTime: "15:30",
    endTime: "23.30",
    temperature: 22,
    scheduleId: 5
  }, {
    startTime: "08:00",
    endTime: "13:00",
    temperature: 21,
    scheduleId: 6
  }, {
    startTime: "14:00",
    endTime: "23.30",
    temperature: 23,
    scheduleId: 6
  }, {
    startTime: "08:00",
    endTime: "13:00",
    temperature: 21,
    scheduleId: 7
  }, {
    startTime: "14:00",
    endTime: "23.30",
    temperature: 23,
    scheduleId: 7
  }];
});