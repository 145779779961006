define("touch/serializers/signup", ["exports", "touch/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() /*snapshot, options*/{
      var json = this._super.apply(this, arguments);
      json.data.type = "users";
      return json;
    }
  });
});