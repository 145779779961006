define('touch/controllers/internal/holiday', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    // Actions
    actions: {

      deleteHoliday: function deleteHoliday(holiday) {
        var _this = this;

        var toaster = Ember.get(this, 'toaster');
        holiday.deleteRecord();
        holiday.save().then(function () {
          toaster.setMessage('Holiday removed', {
            timeout: 5000
          });
          _this.transitionToRoute('internal.holidays');
        }, function () /*error*/{
          holiday.rollbackAttributes();
          toaster.setMessage('Server Error', {
            timeout: 5000
          });
        });
      },
      saveHoliday: function saveHoliday(holiday) {
        var _this2 = this;

        var toaster = Ember.get(this, 'toaster');
        holiday.save().then(function () {
          toaster.setMessage('Holiday saved', {
            timeout: 5000
          });
          _this2.transitionToRoute('internal.holidays');
        }, function (error) {
          holiday.rollbackAttributes();
          toaster.setMessage(error.errors[0].title, {
            timeout: 5000
          });
        });
      }
    }
  });
});