define("touch/controllers/update-app", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      refresh: function refresh() {
        window.location.href = "/";
      }
    }
  });
});