define('touch/components/type-page', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'type-page',
    tagName: 'main'
  });
});