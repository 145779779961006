define('touch/helpers/breaklines', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.breaklines = breaklines;
  function breaklines(text) {
    text = Ember.Handlebars.Utils.escapeExpression(text);
    text = text.replace(/(\r\n|\n|\r)/gm, '<br />');
    return new Ember.Handlebars.SafeString(text);
  }

  exports.default = Ember.Helper.helper(breaklines);
});