define('touch/routes/internal/schedule', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Services
    gatewayChannel: Ember.inject.service(),
    // Methods
    activate: function activate() {
      this.get('gatewayChannel').connect();
    },
    deactivate: function deactivate() {
      this.modelFor(this.routeName).rollbackAttributes();
    },
    model: function model(params) {
      return this.store.findRecord('schedule', params.schedule_id, {
        include: 'periods'
      });
    }
  });
});