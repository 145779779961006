define('touch/mirage/fixtures/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    email: 'user@testing.co.uk',
    firstName: 'Terrence',
    lastName: 'Tester',
    agreeTermsAt: null
  }, {
    email: 'alreadyused@testing.co.uk',
    firstName: 'Tamara',
    lastName: 'Tester',
    agreeTermsAt: '2018-04-12T00:00:00.000Z'
  }];
});