define('touch/components/page-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend({
    // Services
    header: inject.service('header-service'),
    // Attributes
    tagName: 'header',
    classNames: ['page__header'],
    // Computed
    heading: computed.alias('header.heading'),
    backRoute: computed.alias('header.backRoute'),
    backAction: computed.alias('header.backAction'),
    backText: computed.alias('header.backText'),
    actionRoute: computed.alias('header.actionRoute'),
    actionAction: computed.alias('header.actionAction'),
    actionText: computed.alias('header.actionText'),
    hasHeading: computed.bool('heading'),
    hasBackRoute: computed.bool('backRoute'),
    hasBackAction: computed.bool('backAction'),
    hasActionRoute: computed.bool('actionRoute'),
    hasActionAction: computed.bool('actionAction'),
    // Actions
    actions: {
      backAction: function backAction() {
        this.sendAction('backAction');
      }
    }
  });
});