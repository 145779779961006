define('touch/components/gateway-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;
  exports.default = Component.extend({
    toaster: inject.service('smd-toaster'),
    tagName: 'div',
    classNames: ['form'],
    confirm: false,
    claimed: false,
    // Computed
    isDisabled: computed.not('confirm'),
    isClaimed: computed('claimed', function () {
      // add logic to get gateway state from model here
      return this.get('claimed');
    }),
    // Actions
    actions: {
      claim: function claim() {
        this.set('claimed', true);
        this.get('toaster').setMessage('Gateway claimed', { timeout: 5000 });
      },
      unclaim: function unclaim() {
        this.set('claimed', false);
        this.get('toaster').setMessage('Gateway released', { timeout: 5000 });
      }
    }
  });
});