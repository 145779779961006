define('touch/components/notification-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['list'],

    didInsertElement: function didInsertElement() {
      this.$('.x-toggle-btn').addClass('needsclick');
    },


    actions: {
      updateEmailAlerts: function updateEmailAlerts(model) {
        var action = Ember.get(this, 'onSave');
        if (action) {
          model.toggleProperty('receiveEmailAlerts');
          return action();
        }
      },
      updatePushAlerts: function updatePushAlerts(model) {
        var action = Ember.get(this, 'onSave');
        if (action) {
          model.toggleProperty('receivePushAlerts');
          return action();
        }
      }
    }
  });
});