define('touch/components/app-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend({
    // Dependencies
    navigation: inject.service(),
    // Attributes
    tagName: 'header',
    classNames: ['header'],
    classNameBindings: ['openHeader'],
    shouldShowMenu: true,
    menuOpen: false,
    // Computed
    openHeader: computed('menuOpen', function () {
      if (this.get('menuOpen')) {
        return 'header--open';
      }
    }),
    // Actions
    actions: {
      toggleMenu: function toggleMenu() {
        if (this.get('menuOpen')) {
          this.$().css('height', '');
        } else {
          this.$().css('height', '100%');
        }
        this.set('menuOpen', !this.get('menuOpen'));
      },
      logout: function logout() {
        this.sendAction('logoutAction');
      }
    },
    click: function click() /*event*/{
      //Ember.Logger.log('tap');
      this.send('toggleMenu');
    }
  });
});