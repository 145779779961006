define('touch/mirage/factories/address', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    line1: _emberCliMirage.faker.address.streetAddress,
    line2: null,
    line3: null,
    line4: null,
    locality: null,
    town: _emberCliMirage.faker.address.city,
    county: _emberCliMirage.faker.address.county,
    postcode: _emberCliMirage.faker.address.zipCode,
    latitude: _emberCliMirage.faker.address.latitude,
    longitude: _emberCliMirage.faker.address.longitude,
    description: 'address...'
  });
});