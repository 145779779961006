define('touch/mixins/rollbackable-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    deactivate: function deactivate() {
      Ember.Logger.log('rollback');
      var model = this.modelFor(this.routeName);
      model.rollbackAttributes();
    }
  });
});