define('touch/components/type-content', ['exports', 'ember-bem-sauce/mixins/bem-component', 'touch/mixins/colorable', 'touch/mixins/alignable'], function (exports, _bemComponent, _colorable, _alignable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TypeComponent = Ember.Component.extend(_alignable.default, _bemComponent.default, _colorable.default, {
    // Attributes
    base: 'content',
    tagName: 'p',
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifier('padding');
    }
  });

  TypeComponent.reopenClass({
    positionalParams: ['text']
  });

  exports.default = TypeComponent;
});