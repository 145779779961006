define('touch/routes/signup/invalid-gateway', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    // Dependencies
    navigation: Ember.inject.service(),
    // Method
    model: function model() {
      return this.modelFor("signup");
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('signup.gateway');
    },


    beforeModel: function beforeModel() {
      var model = this.modelFor("signup");
      if (model.get('gatewayValidationError') === undefined) {
        this.transitionTo('signup');
      }
    },
    activate: function activate() {
      this.set('navigation.backRoute', 'signup.gateway');
    },
    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      }
    }
  });
});