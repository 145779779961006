define('touch/utils/smd-transitions', ['exports', 'ember-sauce-material-design/utils/smd-transitions'], function (exports, _smdTransitions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdTransitions.default;
    }
  });
});