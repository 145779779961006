define('touch/controllers/internal/help/general-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Properties
    queryParams: ['backRoute'],
    backRoute: 'internal.help.index',
    // Methods
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // reset sticky params
        controller.set('backRoute', 'internal.help.index');
      }
    }
  });
});