define('touch/components/ideal-accordion-body', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    activeIndex: null,
    index: null,
    classNames: ['accordion__body'],
    classNameBindings: ['isActive:accordion__body--active'],
    // Computed
    isActive: computed('activeIndex', 'index', function () {
      return this.get('activeIndex') === this.get('index');
    })
  });
});