define('touch/controllers/internal/schedule/period', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    timeline: Ember.inject.service(),
    // Controllers
    scheduleController: Ember.inject.controller('internal.schedule'),
    scheduleId: Ember.computed.alias('scheduleController.model.id'),
    // Actions
    actions: {
      editPeriod: function editPeriod(period) {
        if (Ember.get(period, "id")) {
          return this.transitionToRoute('internal.schedule.period', period);
        }
      },

      save: function save(period) {
        var _this = this;

        var toaster = Ember.get(this, 'toaster'),
            timeline = Ember.get(this, 'timeline');

        period.save().then(function () {
          toaster.setMessage('Period saved');
          timeline.reset();
          _this.transitionToRoute('internal.schedule');
        }, function (error) {
          period.rollbackAttributes();
          toaster.setMessage(error.errors[0].title, {
            timeout: 5000
          });
        });
      },
      delete: function _delete(period) {
        var _this2 = this;

        var toaster = Ember.get(this, 'toaster'),
            timeline = Ember.get(this, 'timeline');

        // check this is not the last periods in the schedule
        if (period.get('schedule.periods.length') <= 1) {
          toaster.setMessage('You must have at least one period in a schedule', {
            timeout: 5000
          });
          return;
        }

        period.deleteRecord();
        period.save().then(function () {
          toaster.setMessage('Period removed');
          timeline.reset();
          _this2.transitionToRoute('internal.schedule');
        }, function () /*error*/{
          period.rollbackAttributes();
          toaster.setMessage('Failed to remove period', {
            timeout: 5000
          });
        });
      }
    }
  });
});