define('touch/mirage/fixtures/schedules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    name: 'Monday',
    monday: true,
    periodIds: [1, 2]
  }, {
    name: 'Tuesday',
    tuesday: true,
    periodIds: [3, 4]
  }, {
    name: 'Wednesday',
    wednesday: true,
    periodIds: [5, 6]
  }, {
    name: 'Thursday',
    thursday: true,
    periodIds: [7, 8]
  }, {
    name: 'Friday',
    friday: true,
    periodIds: [9, 10]
  }, {
    name: 'Saturday',
    saturday: true,
    periodIds: [11, 12]
  }, {
    name: 'Sunday',
    sunday: true,
    periodIds: [13, 14]
  }];
});