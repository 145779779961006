define('touch/routes/signup/password', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return this.modelFor('signup');
    },
    afterModel: function afterModel(model) {
      var userAgreementSet = Ember.get(model, 'agreeTermsAt');
      var userGatewaySet = Ember.get(model, 'gateway');
      var userAddressSet = Ember.get(model, 'line1');
      var userAddressTownSet = Ember.get(model, 'town');
      var userEmailSet = Ember.get(model, 'email');
      var userFirstNameSet = Ember.get(model, 'firstName');
      var userLastNameSet = Ember.get(model, 'lastName');

      if (Ember.isEmpty(userAgreementSet) || Ember.isEmpty(userGatewaySet) || Ember.isEmpty(userAddressSet) || Ember.isEmpty(userAddressTownSet) || Ember.isEmpty(userEmailSet) || Ember.isEmpty(userFirstNameSet) || Ember.isEmpty(userLastNameSet)) {
        this.router.transitionTo('signup');
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('signup.name');
    },

    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      },
      submit: function submit(model) {
        var _this = this;

        this.intermediateTransitionTo('signup.setting-up');

        model.save().then(function (user) {
          _this.authenticateNewUser(user);
        }, function (error) {
          model.set('signUpError', error.errors[0].title);
          _this.router.transitionTo('signup.error');
          Ember.Logger.log(error);
        });
      }
    },

    authenticateNewUser: function authenticateNewUser(user) {
      var _this2 = this;

      // Authenticate User
      this.get('session').open('touch', user).then(function () /*response*/{
        _this2.transitionTo('internal.home');
      }, function (response) {
        var message;
        if (response.status === 0) {
          message = 'Failed to connect to server';
        } else {
          message = response.responseJSON.errors[0].detail;
        }
        _this2.get('toaster').setMessage(message, { timeout: 5000 });
      });
    }
  });
});