define('touch/mirage/factories/holiday', ['exports', 'ember-cli-mirage', 'moment'], function (exports, _emberCliMirage, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    startsAt: function startsAt() {
      return _emberCliMirage.faker.date.future();
    },
    endsAt: function endsAt() {
      return (0, _moment.default)(this.startsAt).add(7, 'days').format();
    }
  });
});