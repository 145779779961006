define('touch/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/address.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/device.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/device.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/gateway.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/gateway.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/holiday.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/holiday.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/period.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/period.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/schedule.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/schedule.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/address.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/gateways.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/gateways.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/holidays.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/holidays.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/periods.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/periods.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/schedules.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/schedules.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/users.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/users.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/address.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/device.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/device.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/gateway.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/gateway.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/holiday.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/holiday.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/period.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/period.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/schedule.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/schedule.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/gateway.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/gateway.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/schedule.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/schedule.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/user.js should pass ESLint\n\n');
  });
});