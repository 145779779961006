define('touch/services/timeline', ['exports', 'heating-timeline/services/timeline'], function (exports, _timeline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _timeline.default;
    }
  });
});