define('touch/routes/updated-user-agreement', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('session.currentUser');
    },
    afterModel: function afterModel() {
      Ember.set(this, 'session.currentUser.agreeTermsAt', null);
    },

    actions: {
      submit: function submit() {
        var _this = this;

        Ember.set(this, 'session.currentUser.agreeTermsAt', new Date());
        var user = Ember.get(this, 'session.currentUser');

        user.save().then(function () {
          _this.transitionTo('internal.home');
        }, function () /* error */{
          _this.get('toaster').setMessage('Failed to save, please try again', { timeout: 2000 });
        });
      }
    }
  });
});