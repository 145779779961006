define('touch/router', ['exports', 'touch/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(_this, 'metrics').trackPage({
          page: page,
          title: title
        });
      });
    }
  });

  Router.map(function () {
    this.route('external');
    this.route('login');
    this.route('recover-password');
    this.route('styleguide');
    this.route('update-app');
    this.route('updated-user-agreement');
    this.route('signup', function () {
      this.route('gateway');
      this.route('email');
      this.route('name');
      this.route('password');
      this.route('setting-up');
      this.route('invalid-gateway');
      this.route('completed');
      this.route('error');
      this.route('postcode');
      this.route('address');
    });
    this.authenticatedRoute('internal', {
      path: '/'
    }, function () {
      this.route('home', {}, function () {
        this.route('heating');
        this.route('water');
        this.route('combi');
      });
      this.route('on-holiday', {
        path: 'on-holiday/:holiday_id'
      });
      this.route('holidays', function () {
        this.route('new');
      });
      this.route('holiday', {
        path: 'holiday/:holiday_id'
      });
      this.route('settings', function () {
        this.route('account');
        this.route('notifications');
        this.route('gateway');
        this.route('address');
        this.route('password');
        this.route('app');
        this.route('boiler');
      });
      this.route('schedules', function () {
        this.route('heating');
        this.route('water');
      });
      this.route('schedule', {
        path: 'schedule/:schedule_id'
      }, function () {
        this.route('period', {
          path: 'period/:period_id'
        });
        this.route('new-period');
      });

      this.route('new-schedule', function () {
        this.route('heating', function () {
          this.route('days');
          this.route('period');
          this.route('edit-period', {
            path: 'period/edit/:period_id'
          });
          this.route('finish');
        });
      });
      this.route('help', function () {
        this.route('general-view', {
          path: 'general/:id'
        });
        this.route('fault-view', {
          path: 'fault/:code'
        });
      });
    });
    this.route('internal.settings.data-privacy');

    this.route('internal', function () {
      this.route('settings', function () {
        this.route('data-privacy');
      });
    });
  });

  exports.default = Router;
});