define('touch/mixins/holdable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    holdButton: function holdButton(context, targetTempAction, params) {
      var _this = this;

      this.clearTimeouts();
      var waitTimer = window.setTimeout(function () {
        var timer = window.setInterval(function () {
          context[targetTempAction](params);
        }, 100);
        Ember.set(_this, 'buttonTimeout', timer);
      }, 600);
      Ember.set(this, 'buttonWaitTimeout', waitTimer);
    },
    clearTimeouts: function clearTimeouts() {
      window.clearTimeout(Ember.get(this, 'buttonTimeout'));
      window.clearTimeout(Ember.get(this, 'buttonWaitTimeout'));
    }

  });
});