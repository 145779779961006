define('touch/components/scheduler-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    // Services
    scheduler: service('heating-scheduler'),
    // Attributes
    currentStep: computed.alias('scheduler.step'),
    tagName: '',
    iconIndex: {
      1: "calendar",
      2: "bed",
      3: "thermometer",
      4: "houseleave",
      5: "housereturn",
      6: "thermometer",
      7: "moon",
      8: "pen",
      // 9: null,
      10: "clock",
      11: "thermometer",
      12: "clock",
      13: "thermometer",
      14: "clock",
      15: "thermometer",
      16: "clock"
    },
    // Computed
    hasIcon: computed.bool('icon'),
    icon: computed('currentStep', 'iconIndex', function () {
      var index = this.get('iconIndex'),
          step = this.get('currentStep');

      if (step in index) {
        return 'icons/' + index[step];
      }
      return false;
    })
  });
});