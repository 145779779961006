define('touch/components/type-link', ['exports', 'ember-bem-sauce/mixins/bem-component', 'touch/mixins/colorable', 'touch/mixins/alignable'], function (exports, _bemComponent, _colorable, _alignable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_alignable.default, _bemComponent.default, _colorable.default, {
    // Attributes
    base: 'link'
  });
});