define('touch/components/select-box/api', ['exports', 'ember-select-box/components/select-box/api'], function (exports, _api) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _api.default;
    }
  });
});