define('touch/routes/recover-password', ['exports', 'touch/mixins/unauthable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _unauthableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthableRoute.default, _backButtonable.default, {
    model: function model() {
      return this.store.createRecord('recover-password');
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('login');
    },

    actions: {
      goBack: function goBack() {
        this.backButtonHandler();
      },
      recoverPassword: function recoverPassword(model) {

        var url = window.getURL('/recover-password'),
            data = JSON.stringify({
          data: {
            type: "user",
            attributes: {
              email: model.get('email')
            }
          }
        });

        Ember.$.post(url, data).always(function () {
          Ember.run(function () {
            model.set('recoveryEmailSent', true);
          });
        });
      }
    }
  });
});