define('touch/mixins/alignable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      var modifiers = new Ember.A(Ember.get(this, 'modifiers'));
      modifiers.addObjects(['isAlignedCenter:ac', 'isAlignedLeft:al', 'isAlignedRight:ar']);
      Ember.set(this, 'modifiers', modifiers);
      this._super.apply(this, arguments);
    },

    // Align
    isAlignedCenter: Ember.computed.equal('align', 'center'),
    isAlignedLeft: Ember.computed.equal('align', 'left'),
    isAlignedRight: Ember.computed.equal('align', 'right')
  });
});