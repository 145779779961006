define('touch/components/password-form', ['exports', 'touch/components/form-control'], function (exports, _formControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _formControl.default.extend({
    tagName: 'form',
    classNames: [''],
    actions: {
      save: function save() {
        if (this.validate()) {
          this.sendAction('saveAction', this.get('model'));
        }
      }
    }
  });
});