define('touch/components/period-form', ['exports', 'moment', 'touch/mixins/holdable'], function (exports, _moment, _holdable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_TEMP = 30,
      MIN_TEMP = 10;

  exports.default = Ember.Component.extend(_holdable.default, {
    // Services
    toaster: Ember.inject.service('smd-toaster'),
    // Attributes
    tagName: 'div',
    classNames: ['list'],
    model: null,

    increaseTargetTemp: function increaseTargetTemp(model) {
      var temp = parseFloat(model.get('temp'));
      if (temp < MAX_TEMP) {
        model.set('temp', temp + 0.5);
      }
    },

    decreaseTargetTemp: function decreaseTargetTemp(model) {
      var temp = parseFloat(model.get('temp'));
      if (temp > MIN_TEMP) {
        model.set('temp', temp - 0.5);
      }
    },

    // Actions
    actions: {
      holdTempDown: function holdTempDown(model) {
        this.decreaseTargetTemp(model);
        this.holdButton(this, "decreaseTargetTemp", model);
      },
      releaseTempDown: function releaseTempDown() {
        this.clearTimeouts();
      },
      holdTempUp: function holdTempUp(model) {
        this.increaseTargetTemp(model);
        this.holdButton(this, "increaseTargetTemp", model);
      },
      releaseTempUp: function releaseTempUp() {
        this.clearTimeouts();
      },
      handleFastClick: function handleFastClick() {
        Ember.$('.arrowUp').addClass('needsclick');
        Ember.$('.arrowDown').addClass('needsclick');
      },
      setStartTime: function setStartTime(time, date) {

        var start = date,
            end = this.stringToDatetime(Ember.get(this, 'model.endTime')),
            toaster = Ember.get(this, 'toaster'),
            minTime = Ember.get(this, 'minTime');

        if (start >= end) {
          //toaster.setMessage('can`t set a start time after an end time');
        } else if (start < this.stringToDatetime(minTime)) {
          toaster.setMessage('can`t set a start time before ' + minTime);
        } else {
          Ember.set(this, 'model.startTime', time);
        }
      },
      setEndTime: function setEndTime(time, date) {

        var end = date,
            start = this.stringToDatetime(Ember.get(this, 'model.startTime')),
            toaster = Ember.get(this, 'toaster'),
            maxTime = Ember.get(this, 'maxTime');

        if (end <= start) {
          //toaster.setMessage('can`t set an end time before a start time');
        } else if (end > this.stringToDatetime(maxTime)) {
          toaster.setMessage('can`t set a start time after ' + maxTime);
        } else {
          Ember.set(this, 'model.endTime', time);
        }
      }
    },
    stringToDatetime: function stringToDatetime(time) {
      var now = (0, _moment.default)().format('YYYY-MM-DD');
      return (0, _moment.default)(now + ' ' + time).toDate();
    },
    timeOverlapsExistingSchedulePeriods: function timeOverlapsExistingSchedulePeriods(startTime, endTime) {
      var allSchedulePeriods = this.get('model.schedule.periods');
      var editingPeriod = this.get('model');

      var overlappingPeriods = allSchedulePeriods.filter(function (period) {
        if (period.get('id') !== editingPeriod.get('id')) {
          return period.get('start') < startTime && startTime < period.get('end') || period.get('start') < endTime && endTime < period.get('end') || startTime <= period.get('start') && endTime >= period.get('end');
        }
      });

      return overlappingPeriods.length > 0;
    }
  });
});