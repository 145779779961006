define('touch/mixins/routes/back-buttonable', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		cordovaEvents: Ember.inject.service('ember-cordova/events'),
		settingUpRoute: Ember.on('activate', function () {
			this.get('cordovaEvents').on('backbutton', this, 'backButtonHandler');
		}),
		destroyingRoute: Ember.on('deactivate', function () {
			this.get('cordovaEvents').off('backbutton', this, 'backButtonHandler');
		}),
		backButtonHandler: function backButtonHandler() {
			Ember.Logger.error('BackButtonable route should declare its own backButtonHandler method');
		}
	});
});