define('touch/mixins/validation/holiday', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    startDate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      onOrAfter: 'now',
      precision: 'day',
      format: 'YYYY-MM-DD',
      errorFormat: 'DD/MM/YYYY'
    })],
    endDate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      after: Ember.computed.readOnly('model.startDate'),
      precision: 'day',
      format: 'YYYY-MM-DD',
      errorFormat: 'DD/MM/YYYY'
    })],
    startTime: [(0, _emberCpValidations.validator)('presence', true)],
    endTime: [(0, _emberCpValidations.validator)('presence', true)]
  });
});