define('touch/serializers/gateway', ['exports', 'touch/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() /*snapshot, options*/{
      var payload = this._super.apply(this, arguments);

      payload.data.attributes['address-line-1'] = payload.data.attributes['address-line1'];
      payload.data.attributes['address-line-2'] = payload.data.attributes['address-line2'];

      delete payload.data.attributes['address-line1'];
      delete payload.data.attributes['address-line2'];

      return payload;
    },
    normalize: function normalize(_primaryModelClass, payload) {
      payload.attributes['address-line1'] = payload.attributes['address-line-1'];
      payload.attributes['address-line2'] = payload.attributes['address-line-2'];

      delete payload.attributes['address-line-1'];
      delete payload.attributes['address-line-2'];

      return this._super.apply(this, arguments);
    }
  });
});