define('touch/mixins/colorable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      var modifiers = Ember.A(Ember.get(this, 'modifiers'));

      modifiers.addObjects(['isAlt:alt', 'isDanger:danger', 'isDark:dark', 'isLight:light', 'isPrimary:primary', 'isWarning:warning']);
      Ember.set(this, 'modifiers', modifiers);
      this._super.apply(this, arguments);
    },

    // Computed
    isAlt: Ember.computed.equal('color', 'alt'),
    isDanger: Ember.computed.equal('color', 'danger'),
    isDark: Ember.computed.equal('color', 'dark'),
    isLight: Ember.computed.equal('color', 'light'),
    isPrimary: Ember.computed.equal('color', 'primary'),
    isWarning: Ember.computed.equal('color', 'warning')
  });
});