define("touch/helpers/translate-gateway-validation-error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.translateGatewayValidationError = translateGatewayValidationError;
  function translateGatewayValidationError(params) {
    var error = params[0];
    switch (error) {
      case "Box already registered":
        return "This boiler is already connected to an existing email address and account. It is only possible to assign one email address to a Touch Connect Gateway. If you need to remove the current user account configured against this gateway please contact our support team";
      case "Unknown Box":
        return "This is not a gateway pin that we recognise. Please check your pin and try again.";
      case "Box not activated":
        return "This gateway has not been activated yet. Please contact our support team to help resolve this issue.";
      case "Gateway is currently offline":
        return "This gateway is currently offline. Please check your connection and try again. If this continues, please contact our support team to help resolve the issue";
      default:
        return "There appears to be an issue with this gateway. Please contact our support team for further help.";
    }
  }

  exports.default = Ember.Helper.helper(translateGatewayValidationError);
});