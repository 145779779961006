define('touch/utils/select-box/scroll-into-view', ['exports', 'ember-select-box/utils/select-box/scroll-into-view'], function (exports, _scrollIntoView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _scrollIntoView.default;
    }
  });
});