define('touch/helpers/uppercase-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.uppercaseString = uppercaseString;
  function uppercaseString(params) {
    var string = params[0];
    if (string) {
      return params[0].capitalize();
    }
  }

  exports.default = Ember.Helper.helper(uppercaseString);
});