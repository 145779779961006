define('touch/components/countdown-timer', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    // Computed
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.updateSecondsToGo();
      var intervalId = window.setInterval(function () {
        _this.updateSecondsToGo();
      }, 1000);
      this.set('intervalId', intervalId);
    },
    updateSecondsToGo: function updateSecondsToGo() {
      var _this2 = this;

      Ember.run.once(function () {
        var nowUnix = (0, _moment.default)().format('x');
        var endUnix = (0, _moment.default)(_this2.get('endTime')).format('x');
        var unixDiff = endUnix - nowUnix;
        var secondsToGo = Math.round(unixDiff / 1000);
        if (!_this2.isDestroyed) {
          _this2.set('secondsToGo', secondsToGo);
        }
      });
    },
    willDestroy: function willDestroy() {
      window.clearInterval(this.get('intervalId'));
    },

    // Computed
    countdownString: Ember.computed('secondsToGo', function () {
      var secondsToGo = this.roundUpToNearestMinute(this.get('secondsToGo'));
      var hours = Math.floor(secondsToGo / 3600);
      var minutes = Math.floor(secondsToGo % 3600 / 60);

      if (isNaN(hours) || isNaN(minutes)) {
        return '';
      }

      if (hours <= 0 && minutes <= 0) {
        window.clearInterval(this.get('intervalId'));
        return 'manual';
      } else {
        return this.formatTimer(hours, minutes, secondsToGo);
      }
    }),

    roundUpToNearestMinute: function roundUpToNearestMinute(seconds) {
      return seconds + 59;
    },

    formatTimer: function formatTimer(hours, minutes, secondsToGo) {
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (secondsToGo < 60) {
        return '0:01';
      } else {
        return hours + ':' + minutes;
      }
      //return minutes + ':' + seconds;
      //return moment(this.get('endTime')).fromNow();
    }
  });
});