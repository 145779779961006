define('touch/utils/time-helpers', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.decimalToTime = decimalToTime;
  exports.decimalToHours = decimalToHours;
  exports.timeToDecimal = timeToDecimal;
  function decimalToTime(decimal) {
    decimal = Math.abs(decimal);
    // 1.5 = 01:30
    var mins = (0, _lodash.padStart)(parseInt(decimal % 1 * 60), 2, '0'),
        hours = (0, _lodash.padStart)(parseInt(decimal), 2, '0');

    return hours + ':' + mins;
  }

  function decimalToHours(decimal) {
    decimal = Math.abs(decimal);
    // 1.5 = 01:30
    var hours = (0, _lodash.padStart)(parseInt(decimal), 2, '0');

    return hours;
  }

  function timeToDecimal(time) {
    // 01:30 = 1.5
    var bits = time.split(':'),
        hours = parseInt(bits[0]),
        mins = parseInt(bits[1] / 60 * 100);

    return parseFloat(hours + '.' + mins);
  }

  exports.default = {
    decimalToTime: decimalToTime,
    decimalToHours: decimalToHours,
    timeToDecimal: timeToDecimal
  };
});