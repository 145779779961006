define('touch/components/full-screen-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject;
  exports.default = Component.extend({
    currentGatewayState: inject.service(),
    fullScreenError: inject.service(),
    tag: "",
    showModal: null,
    gatewayOffline: null,

    showModalState: Ember.observer('currentGatewayState.isOffline', function () {
      this.set('gatewayOffline', true);
      this.set('showModal', this.get('currentGatewayState.isOffline'));
    }).on('init'),

    errorState: Ember.observer('fullScreenError.errorMessage', function () {
      if (!this.get('currentGatewayState.isOffline')) {
        this.set('gatewayOffline', false);
        this.set('showModal', !Ember.isEmpty(this.get('fullScreenError.errorMessage')));
      }
    }).on('init'),

    actions: {
      retry: function retry() {
        this.sendAction('retryAction');
      },
      logout: function logout() {
        this.set('showModal', false);
        this.sendAction('logoutAction');
      }
    }
  });
});