define('touch/mirage/factories/device', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    operatingState: function operatingState() {
      return _emberCliMirage.faker.random.arrayElement(['on', 'off', 'auto']);
    }
  });
});