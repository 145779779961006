define("touch/services/app-state", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    deviceToken: null,
    devicePlatform: "unknown"
  });
});