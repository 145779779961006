define('touch/components/signup-address-form', ['exports', 'ember-sauce-material-design/components/smd-form'], function (exports, _smdForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _smdForm.default.extend({
    tagName: 'form',
    classNames: ['smd-form', 'smd-form--external scrollable']
  });
});