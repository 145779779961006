define('touch/routes/signup/gateway', ['exports', 'touch/mixins/routes/back-buttonable'], function (exports, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_backButtonable.default, {
    model: function model() {
      return this.modelFor('signup');
    },
    afterModel: function afterModel(model) {
      var userAgreementSet = Ember.get(model, 'agreeTermsAt');

      if (Ember.isEmpty(userAgreementSet)) {
        this.router.transitionTo('signup');
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('signup');
    },

    actions: {
      submit: function submit(model) {
        var _this = this;

        // format json request
        var data = JSON.stringify({
          data: {
            type: 'gateway',
            attributes: {
              serial: model.get('gateway')
            }
          }
        });

        // post to api
        Ember.$.post(window.getURL('/validate-gateway'), data).done(function () {
          Ember.Logger.log('valid');
          _this.router.transitionTo('signup.postcode');
        }).fail(function (response) {
          var signupModel = _this.modelFor('signup');
          Ember.Logger.log('in-valid');

          signupModel.set('gatewayValidationError', response.responseJSON.errors[0].title);
          _this.router.transitionTo('signup.invalid-gateway');
        });
      }
    }
  });
});