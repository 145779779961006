define('touch/components/system-status', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      bool = Ember.computed.bool,
      service = Ember.inject.service;
  exports.default = Component.extend({
    // Services
    session: service(),
    errorChannel: service(),
    currentGatewayState: service(),
    // Attributes
    classNames: ['system-status'],
    classNameBindings: ['messageModifierClass'],
    errorMessage: null,
    temperatureMessage: 'Outside Temp: 7°',
    weatherMessage: 'Weather: Heavy Rain',
    weatherIcon: 'rain',
    holidayIcon: 'sun',
    scheduleIcon: 'thermo-half',
    slideDuration: 8000,
    // Computed
    hasError: bool('errorMessage'),
    latestFault: null,
    messageModifierClass: computed('hasError', function () {
      if (this.get('hasError')) {
        return 'system-status--with-message';
      }
    }),
    inHolidayPeriod: bool('currentGatewayState.holidayEndsAt'),
    holidayMessage: computed('currentGatewayState.holidayEndsAt', function () {
      if (this.get('currentGatewayState.holidayEndsAt')) {
        var holidayEnds = this.get('currentGatewayState.holidayEndsAt'),
            holidayDate = (0, _moment.default)(holidayEnds).format('Do MMM'),
            holidayTime = (0, _moment.default)(holidayEnds).format('HH:mm');
        return 'Your holiday period ends on ' + holidayDate + ' at ' + holidayTime;
      }
    }),
    scheduleMessage: computed('currentGatewayState.centralHeatingPeriodStart', 'currentGatewayState.centralHeatingDisplayMode', 'currentGatewayState.centralHeatingTimerEnds', 'currentGatewayState.centralHeatingState', 'currentGatewayState.centralHeatingClosestScheduledTemperature', 'currentGatewayState.centralHeatingNextPeriodStart', 'currentGatewayState.centralHeatingNextPeriodTargetTemperature', function () {
      var start = this.get('currentGatewayState.centralHeatingPeriodStart'),
          end = this.get('currentGatewayState.centralHeatingPeriodEnd'),
          timerEnds = this.get('currentGatewayState.centralHeatingTimerEnds'),
          scheduledTemperature = this.get('currentGatewayState.centralHeatingClosestScheduledTemperature'),
          nextPeriodStartTime = this.get('currentGatewayState.centralHeatingNextPeriodStart'),
          nextPeriodTargetTemp = this.get('currentGatewayState.centralHeatingNextPeriodTargetTemperature'),
          periodStartTime = _moment.default.utc(start).format('HHmm'),
          periodEndTime = _moment.default.utc(end).format('HHmm'),
          periodDay = _moment.default.utc(start).format('DD'),
          currentTime = (0, _moment.default)().format('HHmm'),
          timerEndsTime = (0, _moment.default)(timerEnds).format('HH:mm'),
          formattedPeriodStartTime = _moment.default.utc(start).format('HH:mm'),
          formattedPeriodEndTime = _moment.default.utc(end).format('HH:mm'),
          formattedCurrentTime = _moment.default.utc().format('HH:mm'),
          endPeriodDayTime = _moment.default.utc(end).format('yyMMddHHmm'),
          nextPeriodStartDayTime = _moment.default.utc(nextPeriodStartTime).format('yyMMddHHmm');

      if (this.boilerIsOffAndTimed()) {
        return 'Your heating is OFF until ' + timerEndsTime;
      }

      if (this.boilerIsOnAndTimed()) {
        return 'Manual Mode is active until ' + timerEndsTime;
      }

      if (this.boilerIsOff()) {
        return 'Your heating is currently OFF';
      }

      if (this.boilerIsOn()) {
        return 'Manual Mode is active';
      }

      if (periodDay == (0, _moment.default)().format('DD')) {
        if (nextPeriodStartDayTime == endPeriodDayTime) {
          return 'Your heating will change to a target temperature of ' + nextPeriodTargetTemp + 'C at ' + formattedPeriodEndTime;
        }

        if (parseInt(periodStartTime) > parseInt(currentTime)) {
          return 'Your heating is scheduled to come on later at ' + formattedPeriodStartTime;
        }

        if (parseInt(periodStartTime) < parseInt(currentTime) && parseInt(periodEndTime) > parseInt(currentTime)) {
          return 'Your heating is scheduled to turn off later at ' + formattedPeriodEndTime;
        }
      } else {
        return 'Your heating is scheduled to come on tomorrow at ' + formattedPeriodStartTime;
      }

      //if (parseInt(periodStartTime) < parseInt(currentTime) && parseInt(periodEndTime) < parseInt(currentTime)) {
      //  return `Your heating is scheduled to come on tomorrow at ${formattedPeriodStartTime}`;
      //}
      return '';
    }),
    optimisationMessage: computed('currentGatewayState.centralHeatingState', 'currentGatewayState.isInOptimisationMode', function () {
      var heatingState = this.get('currentGatewayState.centralHeatingState'),
          isInOptimisationMode = this.get('currentGatewayState.isInOptimisationMode');

      if (heatingState == 'auto' && isInOptimisationMode) {
        return 'Optimisation is enabled';
      }
    }),

    loadWeather: Ember.on('didInsertElement', Ember.observer('currentGatewayState.weatherType', 'currentGatewayState.outsideTemperature', function () {
      var temperature = this.get('currentGatewayState.outsideTemperature');
      var weatherType = this.get('currentGatewayState.weatherType');

      this.set('temperatureMessage', 'Outside, Temp: ' + temperature + '°');
      this.setWeatherIcon(weatherType);
      this.setWeatherMessage(weatherType);
    })),

    setWeatherMessage: function setWeatherMessage(weatherType) {
      switch (weatherType) {
        case '0':
          this.set('weatherMessage', 'Weather: Clear night');
          break;
        case '1':
          this.set('weatherMessage', 'Weather: Sunny day');
          break;
        case '2':
        case '3':
          this.set('weatherMessage', 'Weather: Partly cloudy');
          break;
        case '5':
          this.set('weatherMessage', 'Weather: Mist');
          break;
        case '6':
          this.set('weatherMessage', 'Weather: Fog');
          break;
        case '7':
          this.set('weatherMessage', 'Weather: Cloudy');
          break;
        case '8':
          this.set('weatherMessage', 'Weather: Overcast');
          break;
        case '9':
        case '10':
          this.set('weatherMessage', 'Weather: Light rain shower');
          break;
        case '11':
          this.set('weatherMessage', 'Weather: Drizzle');
          break;
        case '12':
          this.set('weatherMessage', 'Weather: Light rain');
          break;
        case '13':
        case '14':
          this.set('weatherMessage', 'Weather: Heavy rain shower');
          break;
        case '15':
          this.set('weatherMessage', 'Weather: Heavy rain');
          break;
        case '16':
        case '17':
          this.set('weatherMessage', 'Weather: Sleet shower');
          break;
        case '18':
          this.set('weatherMessage', 'Weather: Sleet');
          break;
        case '19':
        case '20':
          this.set('weatherMessage', 'Weather: Hail shower');
          break;
        case '21':
          this.set('weatherMessage', 'Weather: Hail');
          break;
        case '22':
        case '23':
          this.set('weatherMessage', 'Weather: Light snow shower');
          break;
        case '24':
          this.set('weatherMessage', 'Weather: Light snow');
          break;
        case '25':
        case '26':
          this.set('weatherMessage', 'Weather: Heavy snow shower');
          break;
        case '27':
          this.set('weatherMessage', 'Weather: Heavy snow');
          break;
        case '28':
        case '29':
          this.set('weatherMessage', 'Weather: Thunder shower');
          break;
        case '30':
          this.set('weatherMessage', 'Weather: Thunder');
          break;
      }
    },

    setWeatherIcon: function setWeatherIcon(weatherType) {
      switch (weatherType) {
        case '':
          this.set('weatherIcon', 'wind');
          break;
        case '9':
        case '10':
        case '11':
        case '12':
        case '13':
        case '14':
        case '15':
        case '19':
        case '20':
        case '21':
        case '28':
        case '29':
        case '30':
          this.set('weatherIcon', 'rain');
          break;
        case '16':
        case '17':
        case '18':
        case '22':
        case '23':
        case '24':
        case '25':
        case '26':
        case '27':
          this.set('weatherIcon', 'snow');
          break;
        case '2':
        case '3':
        case '5':
        case '6':
        case '7':
        case '8':
          this.set('weatherIcon', 'cloud');
          break;
        case '0':
          this.set('weatherIcon', 'moon');
          break;
        case '1':
          this.set('weatherIcon', 'sun');
          break;
      }
    },

    lastSystemError: Ember.on('didInsertElement', Ember.observer('errorChannel.boilerFaults', function () {
      var fault = this.get('errorChannel.boilerFaults.lastObject');
      if (fault !== undefined) {
        this.set('errorMessage', fault.get('message'));
        this.set('latestFault', fault);
      } else {
        this.set('errorMessage', '');
        this.set('latestFault', null);
      }
    })),

    boilerIsOff: function boilerIsOff() {
      return this.get('currentGatewayState.centralHeatingDisplayState') === 'off';
    },

    boilerIsOffAndTimed: function boilerIsOffAndTimed() {
      return this.get('currentGatewayState.centralHeatingMode') === 'timer' && this.get('currentGatewayState.centralHeatingDisplayState') === 'off' && this.get('currentGatewayState.centralHeatingTimerEnds') !== null;
    },

    boilerIsOn: function boilerIsOn() {
      return this.get('currentGatewayState.centralHeatingDisplayState') === 'on';
    },

    boilerIsOnAndTimed: function boilerIsOnAndTimed() {
      return this.get('currentGatewayState.centralHeatingMode') === 'timer' && this.get('currentGatewayState.centralHeatingDisplayState') === 'on' && this.get('currentGatewayState.centralHeatingTimerEnds') !== null;
    }
  });
});