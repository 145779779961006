define('touch/components/operating-state-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['toggle'],
    state: null,

    _options: [{ label: 'Off', value: 'off', classNames: '' }, { label: 'On', value: 'on', classNames: '' }, { label: 'Auto', value: 'auto', classNames: '' }],

    options: Ember.computed('_options', 'state', function () {
      var _this = this;

      var array = [];
      this.get('_options').forEach(function (state) {
        var obj = { label: state.label,
          value: state.value, classNames: '' };
        if (_this.get('state') === state.value) {
          obj.classNames = "toggle__item--selected";
        }
        array.push(obj);
      });

      return array;
    }),

    actions: {
      togglePress: function togglePress(state) {
        this.sendAction('action', state);
      }
    }
  });
});