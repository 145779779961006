define('touch/components/system-override-modal', ['exports', 'moment', 'ember-modal-dialog/components/modal-dialog'], function (exports, _moment, _modalDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      alias = _Ember$computed.alias,
      service = Ember.inject.service;
  exports.default = _modalDialog.default.extend({
    currentGatewayState: service(),
    gatewayChannel: service(),
    homeService: service(),
    toaster: service('smd-toaster'),
    // Attributes
    //classNames: 'override',
    //classNameBindings: [
    //  'stateModifierClass',
    //],
    state: 'heating', // Heating or Water
    // Computed
    isCombi: alias('homeService.isCombi'),
    isWater: equal('state', 'water'),
    isHeating: equal('state', 'heating'),
    stateModifierClass: computed('state', 'homeService.heatingState', 'homeService.waterState', function () {
      if (this.get('state') === 'water') {
        return 'override--' + this.get('homeService.waterState');
      }
      return 'override--' + this.get('homeService.heatingState');
    }),
    autoOverrideText: computed('currentGatewayState.centralHeatingNextPeriodStart', 'homeService.heatingState', function () {
      var time = _moment.default.utc(this.get('currentGatewayState.centralHeatingNextPeriodStart')).format('HH:mm');
      if (this.get('homeService.heatingState') === 'on') {
        return 'Heating will continue until the next period at ' + time;
      } else {
        return 'Heating will stop until the next period at ' + time;
      }
    }),
    manualOverrideText: computed('homeService.heatingState', function () {
      if (this.get('homeService.heatingState') === 'on') {
        return 'Heating will continue until switched off';
      } else {
        return 'Heating will stop until switched on';
      }
    }),
    autoStatusText: computed('currentGatewayState.centralHeatingPeriodStart', 'currentGatewayState.centralHeatingPeriodEnd', 'homeService.heatingState', function () {
      // let time = moment.utc(this.get('currentGatewayState.centralHeatingNextPeriodStart')).format('HH:mm');
      // if (this.get('homeService.heatingState') === "on") {
      //   return 'Heating will continue until the next period at ' + time;
      // } else {
      //   return 'Heating will stop until the next period at ' + time;
      // }
      var periodStartTime = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodStart')).format('HHmm');
      var periodEndTime = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodEnd')).format('HHmm');
      var currentTime = (0, _moment.default)().format('HHmm');

      if (parseInt(periodStartTime) > parseInt(currentTime)) {
        var time = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodStart')).format('HH:mm');
        return 'Your heating is scheduled to come on later at ' + time;
      }

      if (parseInt(periodStartTime) < parseInt(currentTime) && parseInt(periodEndTime) > parseInt(currentTime)) {
        var _time = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodEnd')).format('HH:mm');
        return 'Your heating is scheduled to turn off later at ' + _time;
      }

      if (parseInt(periodStartTime) < parseInt(currentTime) && parseInt(periodEndTime) < parseInt(currentTime)) {
        var _time2 = _moment.default.utc(this.get('currentGatewayState.centralHeatingPeriodStart')).format('HH:mm');
        return 'Your heating is scheduled to come on tomorrow at ' + _time2;
      }
    }),
    overrideType: computed('currentGatewayState.centralHeatingMode', function () {
      return this.get('currentGatewayState.centralHeatingMode');
    }), // Manual, Timer or Auto
    gatewayOverrideTime: computed('currentGatewayState.centralHeatingTimerMinutes', function () {
      if (this.get('currentGatewayState.centralHeatingTimerMinutes') !== '00:00' && this.get('currentGatewayState.centralHeatingTimerMinutes') !== 0) {
        return this.get('currentGatewayState.centralHeatingTimerMinutes');
      } else {
        return '00:15';
      }
    }),
    // Observers
    typeChanged: Ember.observer('overrideType', function () {
      if (this.get('isWater')) {
        this.set('homeService.waterOverrideType', this.get('overrideType'));
      } else {
        this.set('homeService.heatingOverrideType', this.get('overrideType'));
      }
    }),

    didInsertElement: function didInsertElement() {
      this.startTimerForClosure(5000);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('timer'));
    },

    startTimerForClosure: function startTimerForClosure(milliseconds) {
      var _this = this;

      var runningLater = Ember.run.later(function () {
        _this.updateHeatingStateAndClose();
      }, milliseconds);

      this.set('timer', runningLater);
    },

    updateHeatingStateAndClose: function updateHeatingStateAndClose() {
      this.get('gatewayChannel').sendHeatingState();
      this.sendAction('closeAction');
    },

    // Actions
    actions: {
      setStartTime: function setStartTime(time, date) {
        Ember.set(this, 'overrideTime', time);
      },
      handleFastClick: function handleFastClick() {
        $('.arrowUp').addClass('needsclick');
        $('.arrowDown').addClass('needsclick');
        $('.flatpickr-time .numInput').prop('disabled', true);
      },
      goToMenu: function goToMenu() {
        Ember.run.cancel(this.get('timer'));
        //this.set('homeService.mode', 'menu');
        var time = Ember.get(this, 'overrideTime');

        if (time === '00:00' || Ember.isEmpty(time)) {
          time = '00:15';
        }

        var timeToAdd = time.replace(':', '');
        var currentTime = (0, _moment.default)().format('HHmm');
        var combinedTimes = parseInt(currentTime) + parseInt(timeToAdd);

        if (combinedTimes > 2345) {
          this.get('toaster').setMessage('Currently this action will not go past 23.45, please continue after 00.00', {
            timeout: 5000
          });
        }

        this.set('homeService.heatingOverrideTime', time);
        this.updateHeatingStateAndClose();
      },
      stopTimer: function stopTimer() {
        Ember.run.cancel(this.get('timer'));
      }
    }
  });
});