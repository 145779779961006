define('touch/routes/internal/schedule/index', ['exports', 'touch/mixins/rollbackable-route', 'touch/mixins/routes/back-buttonable'], function (exports, _rollbackableRoute, _backButtonable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackableRoute.default, _backButtonable.default, {
    // Servuces
    toaster: Ember.inject.service('smd-toaster'),
    timeline: Ember.inject.service(),
    // Methods
    activate: function activate() {
      Ember.get(this, 'timeline').reset();
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        var parentController = this.controllerFor('internal.schedule');
        // set new period flag to false
        parentController.set('enableNewPeriods', false);
      }
    },
    backButtonHandler: function backButtonHandler() {
      this.transitionTo('internal.schedules.heating');
    }
  });
});