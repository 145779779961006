define('touch/mixins/schedule-wizard-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      service = Ember.inject.service;
  exports.default = Mixin.create({
    // Services
    header: service('header-service'),
    scheduler: service('heating-scheduler'),
    toaster: service('smd-toaster'),
    // Attributes
    title: null,
    // Methods
    activate: function activate() {
      this.set('header.heading', this.get('title'));
    },
    loadActiveSchedule: function loadActiveSchedule(transition) {
      var step = transition.queryParams.step;
      if (this.get('scheduler.hasActiveSchedule') && step) {
        this.set('scheduler.step', step);
        return this.get('scheduler.schedule');
      }
      this.transitionToStart();
      return false;
    },
    loadActivePeriod: function loadActivePeriod(transition) {
      var step = transition.queryParams.step;
      if (this.get('scheduler.hasActivePeriod') && step) {
        this.set('scheduler.step', step);
        return this.get('scheduler.activePeriod');
      }
      this.transitionToStart();
      return false;
    },
    transitionToStart: function transitionToStart() {
      var data = {
        queryParams: {
          step: 0
        }
      };
      this.transitionTo(this.get('scheduler.startRoute'), data);
    },
    createFirstPeriod: function createFirstPeriod() {
      var scheduler = this.get('scheduler');

      // check we dont already have a morning period
      if (scheduler.get('schedule.periods.length') === 0) {
        var defaults = scheduler.get('morningPeriodDefaults'),
            period = this.store.createRecord('period', defaults);

        scheduler.startNewPeriod(period);
      }
    },
    createSecondPeriod: function createSecondPeriod() {
      var scheduler = this.get('scheduler');
      // check we dont already have an afternoon period
      if (scheduler.get('schedule.periods.length') < 2) {
        var defaults = scheduler.get('afternoonPeriodDefaults'),
            period = this.store.createRecord('period', defaults);

        scheduler.startNewPeriod(period);
      }
    },
    createExtraPeriod: function createExtraPeriod(range) {
      var scheduler = this.get('scheduler');
      // check we dont already have an afternoon period
      if (scheduler.get('schedule.periods.length') < 6) {
        var period = this.store.createRecord('period', {
          start: range.get('start'),
          end: range.get('end'),
          temp: 22
        });

        scheduler.startNewPeriod(period);
      }
    },
    // Actions
    actions: {
      next: function next() {
        var nextRoute = this.get('scheduler.nextRoute'),
            step = this.get('scheduler').nextStep();

        return this.transitionTo(nextRoute, {
          queryParams: {
            step: step
          }
        });
      },
      back: function back() {
        this.get('scheduler').rollbackStep();

        window.history.back();
      }
    }
  });
});