define('touch/components/setting-up-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject;
  exports.default = Component.extend({
    tag: "",
    showModal: null,
    model: null,

    showModalState: Ember.observer('model.fullySetup', function () {
      if (this.get('model') == null) return;

      this.set('showModal', !this.get('model.fullySetup'));
    }).on('init'),

    actions: {
      retry: function retry() {
        this.sendAction('retryAction');
      },
      logout: function logout() {
        this.set('showModal', false);
        this.sendAction('logoutAction');
      }
    }
  });
});