define('touch/routes/internal/home/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend({
    latestBoilerFault: '',
    queryParams: {
      send_new_state: {
        refreshModel: false
      }
    },

    homeService: service(),
    currentGatewayState: service(),
    gatewayChannel: service(),

    beforeModel: function beforeModel(transitionTo) {
      if (this.userWantsToUpdateState(transitionTo.queryParams)) {
        this.sendUpdatedHeatingState();
      }
      // if (this.get('currentGatewayState.hotWaterState') !== this.get('homeService.waterState')
      // && this.get('currentGatewayState.hotWaterState') !== null) {
      //   // Update water state
      //   Ember.Logger.log('send water override');
      //   this.get('currentGatewayState').syncStateFromHomeSocket();
      //   let state = this.get('currentGatewayState').getStateForSocket();
      //   Ember.Logger.log(state);
      //   this.get('gatewayChannel').setOperatingState(state);
      // }
    },

    resetController: function resetController(controller, isExiting, _transition) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('send_new_state', undefined);
      }
    },

    userWantsToUpdateState: function userWantsToUpdateState(params) {
      return params && params.send_new_state === "true";
    },

    sendUpdatedHeatingState: function sendUpdatedHeatingState() {
      if (this.get('currentGatewayState.centralHeatingDisplayState') !== null) {
        // Update heating state
        this.get('currentGatewayState').syncStateFromHomeSocket();
        var state = this.get('currentGatewayState').getStateForSocket();
        this.get('gatewayChannel').setOperatingState(state);
      }
    }
  });
});