define('touch/components/smd-fab', ['exports', 'ember-sauce-material-design/components/smd-fab'], function (exports, _smdFab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smdFab.default;
    }
  });
});