define('touch/mirage/factories/period', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    startTime: _emberCliMirage.faker.random.number(0, 24),
    endTime: _emberCliMirage.faker.random.number(0, 24),
    temperature: _emberCliMirage.faker.random.number(10, 30)
  });
});