define('touch/routes/internal', ['exports', 'touch/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    pollingInterval: null,

    model: function model() {
      return this.get('session.currentUser.gateway');
    },

    afterModel: function afterModel() {
      var latestUserAgreementDate = _environment.default.latestUserAgreement;
      var termsAgreedAt = Ember.get(this, 'session.currentUser.agreeTermsAt');
      var latestAgreementIsNewer = (0, _moment.default)(latestUserAgreementDate).isAfter(termsAgreedAt);

      if (latestAgreementIsNewer || termsAgreedAt === null) {
        this.transitionTo('updated-user-agreement');
      }
    },


    maybePollForSetupChanges: Ember.observer('session.currentUser.gateway.fullySetup', function () {
      if (this.get('session.currentUser') === undefined) {
        this.stopPolling();
        return;
      }

      if (this.get('session.currentUser.gateway.fullySetup')) {
        this.stopPolling();
      } else {
        this.startPolling();
      }
    }).on('init'),

    startPolling: function startPolling() {
      var _this = this;

      var interval = Ember.run.later(function () {
        _this.modelFor('internal').reload();
      }, 30000);

      this.set('pollingInterval', interval);
    },

    stopPolling: function stopPolling() {
      Ember.run.cancel(this.get('pollingInterval'));
    },

    actions: {
      retry: function retry() {
        this.modelFor('internal').reload();
      }
    }
  });
});